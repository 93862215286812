<app-modal  i18n-title title="Ihre Anfrage">
    <ng-container headerActions></ng-container>
    <ng-container content>

        <ion-text class="important-notice ion-text-left ion-margin-bottom" i18n>
            <p>Bei Dispo-Lieferungen können Portokosten des Lieferanten anfallen, diese werden Ihnen in Rechnung gestellt. Außerdem können wir Bestellungen sobald sie dem Lieferanten übermittelt wurden <strong>nicht mehr stornieren</strong>,
                sowie disponierte Artikel <strong>nicht mehr zurücknehmen</strong> bzw. an den Hersteller zurückgeben.<br /><br />Bitte prüfen Sie Ihre Bestelldaten sorgfältig.</p>
        </ion-text>
        <form [formGroup]="validationFormGroup" (ngSubmit)="sendDispositionRequest(validationFormGroup.value)" class="form">
            <app-dropdown-input
                label="Ansprechpartner" i18n-label
                formControlName="contactPerson"
                placeholder="{{tooltips.communication_zone_contact_person}}"
                isRequired="true"
                [validationFieldEnum]="validationFieldsEnum.contactPerson"
                [validators]="validators"
                [items]="contactNames"
                [isInvalid]="validationFormGroup.controls.contactPerson?.dirty | formInputIsInvalid : validationFormGroup.controls.contactPerson?.valid"
                [tooltipInvalidValue]="validationFormGroup.controls.contactPerson | formInputErrorMessage : validationFormGroup.controls.contactPerson.value"
            ></app-dropdown-input>
            <ion-item class="background-input" appTooltip ttp="bottom" ttk="dispo_request_qty">
                <ion-input
                    i18n-label
                    type="text"
                    formControlName="qty"
                    label="Menge"
                    class="required"
                    [ngClass]="{
                        'invalid': validationFormGroup.controls.qty?.dirty | formInputIsInvalid : validationFormGroup.controls.qty?.valid,
                    }"
                    [placeholder]="tooltips.dispo_request_qty"
                ></ion-input>
                <ion-icon
                    name="alert-circle"
                    color="primary"
                    *ngIf="validationFormGroup.controls.qty?.dirty | formInputIsInvalid : validationFormGroup.controls.qty?.valid"
                    appTooltip
                    [tt]="validationFormGroup.controls.qty | formInputErrorMessage : validationFormGroup.controls.qty.value : 'Die Menge muss eine Ganzzahl sein.'"
                    ttp="right">
                </ion-icon>
            </ion-item>
            <ion-item class="background-input">
                <ion-input
                    i18n-label
                    type="text"
                    formControlName="productName"
                    label="Produktname"
                    class="required"
                    [ngClass]="{
                        'invalid': validationFormGroup.controls.productName?.dirty | formInputIsInvalid : validationFormGroup.controls.productName?.valid,
                    }"
                    [maxlength]="fieldLengths.productName"
                ></ion-input>
                <ion-icon
                    name="alert-circle"
                    color="primary"
                    *ngIf="validationFormGroup.controls.productName?.dirty | formInputIsInvalid : validationFormGroup.controls.productName?.valid"
                    appTooltip
                    [tt]="validationFormGroup.controls.productName | formInputErrorMessage : validationFormGroup.controls.productName.value"
                    ttp="right">
                </ion-icon>
            </ion-item>
            <ion-item class="background-input item-input" appTooltip ttp="bottom" ttk="dispo_request_is_narcotic">
                    <ion-checkbox
                        mode="md"
                        formControlName="isNarcotic"
                        [class.invalid]="validationFormGroup.controls.isNarcotic?.dirty | formInputIsInvalid : validationFormGroup.controls.isNarcotic?.valid"
                    >
                        <div class="label" i18n>Betäubungsmittel</div>
                    </ion-checkbox>
                    <ion-icon
                        name="alert-circle"
                        color="primary"
                        *ngIf="validationFormGroup.controls.isNarcotic?.dirty | formInputIsInvalid : validationFormGroup.controls.isNarcotic?.valid"
                        appTooltip
                        [tt]="validationFormGroup.controls.isNarcotic | formInputErrorMessage : validationFormGroup.controls.isNarcotic.value"
                        ttp="right">
                    </ion-icon>
            </ion-item>
            <ion-item class="background-input" appTooltip ttp="bottom" ttk="dispo_request_package_size">
                <ion-input
                    i18n-label
                    type="text"
                    formControlName="packageSize"
                    label="Packungseinheit"
                    [ngClass]="{
                        'invalid': validationFormGroup.controls.packageSize?.dirty | formInputIsInvalid : validationFormGroup.controls.packageSize?.valid,
                    }"
                    [maxlength]="fieldLengths.packageSize"
                    [placeholder]="tooltips.dispo_request_package_size"
                ></ion-input>
                <ion-icon
                    name="alert-circle"
                    color="primary"
                    *ngIf="validationFormGroup.controls.packageSize?.dirty | formInputIsInvalid : validationFormGroup.controls.packageSize?.valid"
                    appTooltip
                    [tt]="validationFormGroup.controls.packageSize | formInputErrorMessage : validationFormGroup.controls.packageSize.value"
                    ttp="right">
                </ion-icon>
            </ion-item>
            <ion-item class="background-input" appTooltip ttp="bottom" ttk="dispo_request_dosage_form">
                    <ion-input
                        i18n-label
                        type="text"
                        formControlName="dosageForm"
                        label="Darreichung"
                        [ngClass]="{
                            'invalid': validationFormGroup.controls.dosageForm?.dirty | formInputIsInvalid : validationFormGroup.controls.dosageForm?.valid,
                        }"
                        [maxlength]="fieldLengths.dosageForm"
                        [placeholder]="tooltips.dispo_request_dosage_form"
                    ></ion-input>
                    <ion-icon
                        name="alert-circle"
                        color="primary"
                        *ngIf="validationFormGroup.controls.dosageForm?.dirty | formInputIsInvalid : validationFormGroup.controls.dosageForm?.valid"
                        appTooltip
                        [tt]="validationFormGroup.controls.dosageForm | formInputErrorMessage : validationFormGroup.controls.dosageForm.value"
                        ttp="right">
                    </ion-icon>
            </ion-item>
            <ion-item class="background-input" appTooltip ttp="bottom" ttk="dispo_request_producer">
                <ion-input
                    i18n-label
                    type="text"
                    formControlName="producer"
                    label="Hersteller"
                    [ngClass]="{
                        'invalid': validationFormGroup.controls.producer?.dirty | formInputIsInvalid : validationFormGroup.controls.producer?.valid,
                    }"
                    [maxlength]="fieldLengths.producer"
                    [placeholder]="tooltips.dispo_request_producer"
                ></ion-input>
                <ion-icon
                    name="alert-circle"
                    color="primary"
                    *ngIf="validationFormGroup.controls.producer?.dirty | formInputIsInvalid : validationFormGroup.controls.producer?.valid"
                    appTooltip
                    [tt]="validationFormGroup.controls.producer | formInputErrorMessage : validationFormGroup.controls.producer.value"
                    ttp="right">
                </ion-icon>
            </ion-item>
            <ion-item class="background-input">
                <ion-input
                    i18n-label
                    type="text"
                    formControlName="pzn"
                    label="PZN"
                    [ngClass]="{
                        'invalid': validationFormGroup.controls.pzn?.dirty | formInputIsInvalid : validationFormGroup.controls.pzn?.valid,
                    }"
                    [maxlength]="fieldLengths.pzn"
                ></ion-input>
                <ion-icon
                    name="alert-circle"
                    color="primary"
                    *ngIf="validationFormGroup.controls.pzn?.dirty | formInputIsInvalid : validationFormGroup.controls.pzn?.valid"
                    appTooltip
                    [tt]="validationFormGroup.controls.pzn | formInputErrorMessage : validationFormGroup.controls.pzn.value"
                    ttp="right">
                </ion-icon>
            </ion-item>
            <div class="form-field-container">
                <ion-item class="background-input" appTooltip ttp="bottom" ttk="dispo_request_additional_information">
                    <ion-textarea
                        i18n-label
                        type="text"
                        formControlName="additionalInformation"
                        aria-label="Weitere Informationen"
                        label="Weitere Informationen"
                        class="large"
                        [ngClass]="{
                            'invalid': validationFormGroup.controls.additionalInformation?.dirty | formInputIsInvalid : validationFormGroup.controls.additionalInformation?.valid,
                        }"
                        [maxlength]="fieldLengths.additionalInformation"
                        [placeholder]="tooltips.dispo_request_additional_information"
                    ></ion-textarea>
                    <ion-icon
                        name="alert-circle"
                        color="primary"
                        *ngIf="validationFormGroup.controls.additionalInformation?.dirty | formInputIsInvalid : validationFormGroup.controls.additionalInformation?.valid"
                        appTooltip
                        [tt]="validationFormGroup.controls.additionalInformation | formInputErrorMessage : validationFormGroup.controls.additionalInformation.value"
                        ttp="right">
                    </ion-icon>
                </ion-item>
                <div class="field-limit large" i18n>Noch {{fieldLengths.additionalInformation - validationFormGroup.value['additionalInformation'].length}} Zeichen</div>
            </div>
            <div class="required-fields"><span class="required">*</span> Benötigte Felder</div>
        </form>

    </ng-container>
    <!-- Footer -->
    <ng-container footer>
        <div>
            <ion-button
                    id="{{matomoId}}"
                    [class.disabled]="!validationFormGroup.valid"
                    appTooltip
                    ttk="{{!validationFormGroup.valid ? 'invalid_disposition_request' : null}}"
                    ttp="left"
                    (click)="validationFormGroup.valid ? sendDispositionRequest(validationFormGroup.value) : null"
                    i18n
            >Anfrage senden</ion-button>
        </div>
    </ng-container>
</app-modal>
