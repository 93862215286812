import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getVaccineDoses'
})
export class GetVaccineDosesPipe implements PipeTransform {
    transform(doses: string, quantity: number): number {
        if (doses && parseInt(doses, 10)) {
            return parseInt(doses, 10) * quantity;
        }
        return null;
    }
}
