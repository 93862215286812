import { Component, effect, ElementRef, inject, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatomoTracker } from '@codeculture/ngx-matomo';
import packageInfo from '../../../../../../../package.json';
import { ModalService } from '../../../services/modal.service';
import { NavigationLabel } from '../../../config/navigation.config';
import { NavigationEnum } from '../../../enums/navigation.enum';
import { NavigationRoutesEnum } from '../../../../navigation/navigation.routes';
import { ModalClassEnum } from '../../../enums/modal-class.enum';
import { SettingsRoutesEnum } from '../../../../pages/settings/settings.routes';
import { InfoModalEntryInterface, InfoModalInterface } from '../../../interfaces/info-modal.interface';
import { unsubscribe } from '../../../util/subscriptions.util';
import { InfoModalsService } from '../../../services/infoModals.service';
import { NotificationCountsVar } from '../../../store/locals/notificationCounts.var';
import { ReleaseNoteMutations } from '../../../store/graphql/mutations/release-notes.graphql';

@Component({
    selector: 'app-info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent {

    static modalClass = ModalClassEnum.infoModal;

    version = packageInfo ? packageInfo.version : '';
    notificationCountsSubscription: Subscription;

    protected readonly pageTitles = NavigationLabel;
    protected readonly NavigationRoutesEnum = NavigationRoutesEnum;
    protected readonly NavigationEnum = NavigationEnum;
    protected readonly window = window;
    protected readonly SettingsRoutesEnum = SettingsRoutesEnum;

    // Injects
    private modalService = inject(ModalService);
    private infoModalsService = inject(InfoModalsService);
    private notificationCountsVar = inject(NotificationCountsVar);
    private releaseNoteMutations = inject(ReleaseNoteMutations);
    private matomoTracker = inject(MatomoTracker);

    // Interfaces
    infoModal: InfoModalInterface = null;
    currentItem : InfoModalEntryInterface = null;

    constructor(private renderer: Renderer2, private el: ElementRef) {
        effect(() => {
            const data = this.infoModalsService.infoModals();
            if(data?.length > 0) {
                this.infoModal = data.at(0);
                this.setCurrentItem(data.at(0).entries.at(0), false);
            }
        });

        unsubscribe(this.notificationCountsSubscription);
        this.notificationCountsSubscription = this.notificationCountsVar.get().subscribe(counts => {
            if (counts?.showReleaseNoteHint) {
                this.releaseNoteMutations.removeReleaseNoteHint();
            }
        });
    }

    /**
     * Handles the click event on the header item.
     * Allows the user to manually navigate through the modal entries.
     * @param item - The header item that was clicked.
     */
    onHeaderClick(item: InfoModalEntryInterface) {
        this.setCurrentItem(item);
    }

    /**
     * Returns the active item.
     * The item considered active is the one being currently shown to the user.
     * @returns The active item.
     */
    getActiveItem() : InfoModalEntryInterface {
        return this.currentItem;
    }

    /**
     * Returns the highest ID value from the entries of the infoModal.
     * It's used to control the visibility of the 'Weiter' and 'Verstanden' buttons.
     * @returns The highest ID value.
     */
    getHighestID(): number {
        return Math.max(...this.infoModal?.entries.map(item => item.id));
    }

    /**
     * Retrieves the lowest ID from the `infoModal` entries.
     * It's used to control the visibility of the 'Zurück' button.
     * @return The lowest ID value found in the `infoModal` entries.
     */
    getLowestID() : number {
        return Math.min(...this.infoModal?.entries.map(item => item.id));
    }

    /**
     * Sets the next item in the infoModal entries as the active item.
     * The active item is obtained using the getActiveItem() method.
     * Important:
     *
     * @return This method does not return a value.
     */
    setNextItemAsActive() : void {
        const activeItem = this.getActiveItem();
        const currentIndex = this.infoModal?.entries.indexOf(activeItem);
        const nextIndex = (currentIndex + 1) % this.infoModal?.entries.length;
        this.setCurrentItem(this.infoModal?.entries[nextIndex]);
    }

    setPreviousItemAsActive() : void {
        const activeItem = this.getActiveItem();
        const currentIndex = this.infoModal?.entries.indexOf(activeItem);
        const previousIndex = (currentIndex - 1 + this.infoModal?.entries.length) % this.infoModal?.entries.length;
        this.setCurrentItem(this.infoModal?.entries[previousIndex]);
    }

    onCloseButtonClick() {
        void this.modalService.dismiss();
    }

    trackBy(index: number, item: InfoModalEntryInterface) {
        return item.id;
    }

    private setCurrentItem(item: InfoModalEntryInterface, trackThis = true) {
        this.currentItem = item;

        // Reset scroll position
        const contentCol = this.el.nativeElement.querySelector('.info-content');
        if (contentCol) {
            this.renderer.setProperty(contentCol, 'scrollTop', 0);
        }


        if (trackThis) {
        const title = `${this.infoModal.version}: ${item.title}`;
            this.matomoTracker.trackEvent(
                'Info Modal',
                'Entry changed',
                title,
                item.id
            );
        }
    }
}
