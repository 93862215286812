import { Renderer2, Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ScriptService {

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) { }

    /**
     * Append the JS tag to the Document Body.
     * @param renderer The Angular Renderer
     * @param src The path to the script
     * @returns the script element
     */
    public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
        // check that script doesn't already exist
        const scripts = Array.from(this.document.getElementsByTagName('script'));
        for (const script of scripts) {
            if (script.src.includes(src)) {
                return script;
            }
        }

        const scriptElem = renderer.createElement('script');
        scriptElem.type = 'text/javascript';
        scriptElem.src = src;
        renderer.appendChild(this.document.body, scriptElem);
        return scriptElem;
    }

    public unloadJsScript(src: string) {
        const scripts = Array.from(this.document.getElementsByTagName('script'));
        for (const script of scripts) {
            if (script.src.includes(src)) {
                script.parentNode.removeChild(script);
            }
        }
    }
}
