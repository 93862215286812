<ng-container *ngIf="!isLoading(); else initialLoadingTemplate">
    <div class="table-wrapper" [class.no-sidebar]="!(isSidebarOpen() && data()?.length !== 0)">
        <div class="table-filters">
            <ng-container *ngIf="bulkModeAvailable && bulkModeActive(); else filtersOutput">
                <ng-container *ngTemplateOutlet="bulkTemplateRef" />
            </ng-container>
        </div>
        <div class="table">
            <div class="search-in-progress" [hidden]="!searchInProgress || !searchInProgress()">
                <div>
                    <ion-spinner color="primary" name="crescent"></ion-spinner>
                    <ion-text><h1 i18n>Suche wird durchgeführt, dies dauert einen Moment. <br /> Bitte warten...</h1></ion-text>
                </div>
            </div>
            <p-table *ngIf="!searchInProgress || !searchInProgress()"
                [id]="tableId"
                #table
                [class.no-data]="data()?.length === 0"
                [value]="data()"
                [columns]="columns"
                [scrollable]="true"
                scrollHeight="100%"
                [rows]="numberOfRows"
                [expandedRowKeys]="expandedRowKeys ? expandedRowKeys() : {'-1': false}"
                [rowTrackBy]="rowTrackBy"
                [dataKey]="'id'"
                [paginator]="true"
                [paginatorDropdownAppendTo]="'body'"
                [rowsPerPageOptions]="[25, 50, 100]"
                [showCurrentPageReport]="true"
                [first]="first"
                (onPage)="pageChange($event)"
                currentPageReportTemplate="Ergebnisse {first} bis {last}">
                <ng-template pTemplate="header">
                    <!-- Wrapper required, if *ngif is used on pTemplate the header disappears sometimes -->
                    <ng-container *ngIf="data()?.length !== 0">
                        <ng-container *ngTemplateOutlet="headerTemplateRef" />
                        <ng-container *ngIf="!headerTemplateRef">
                            <tr>
                                <th *ngIf="bulkModeAvailable"
                                    id="col-bulk"
                                    class="col-bulk"
                                    [class.hidden]="!bulkModeActive() || bulkSelectAction() === BulkActionCodeEnum.DEFAULT"
                                ></th>
                                <th [id]="col.id"
                                    class="header-element {{col.class}}"
                                    [style.--col-width]="col.width"
                                    [class.clickable]="col?.sortable"
                                    (click)="col?.sortable && onSortChange(col.dataKey)"
                                    appTooltip [ttk]="col?.ttKey" ttp="bottom"
                                    *ngFor="let col of columns"
                                >
                                    <div class="{{col.class}}">
                                        {{col.title}}
                                        <div class="sorting-action" *ngIf="col?.sortable && sort">
                                            <ion-icon [class.active]="col.dataKey === sort().field && sort().order === sortEnum.desc" name="caret-up-sharp" size="small" />
                                            <ion-icon [class.active]="col.dataKey === sort().field && sort().order === sortEnum.asc" name="caret-down-sharp" size="small" />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </ng-container>
                    </ng-container>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex" let-expanded="expanded">
                    <ng-container *ngIf="rowData">
                        <ng-container *ngTemplateOutlet="bodyTemplateRef; context: {rowData, columns, i, expanded}" />
                    </ng-container>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-row>
                    <ng-container *ngTemplateOutlet="rowExpansionTemplateRef; context:{row}" />
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <ng-container *ngTemplateOutlet="tableNoResults" />
                </ng-template>
                <ng-template pTemplate="loadingbody" let-columns="columns">
                    <tr class="skeleton-row" [style]="{height: rowHeight}">
                        <td *ngFor="let col of columns;trackBy:trackBy" [style.--col-width]="col.width">
                            <ion-skeleton-text [animated]="true" />
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorright" i18n>
                    Einträge per Seite
                </ng-template>
            </p-table>
        </div>
        <div class="table-sidebar" *ngIf="isSidebarOpen() && data()?.length !== 0">
            <app-sidebar [id]="tableId + 'sidebar'" [data]="sidebarData" [actionsPopoverRef]="actionsPopoverRef" [detailsModalRef]="detailsModalRef" (closeSidebar)="onCloseSidebar()"/>
        </div>
    </div>
</ng-container>


<!--###################### default filters template ######################-->
<ng-template #defaultFilters>
    <div class="filter-container" *ngIf="filterConfig">
        <div class="left-inner-container">
            <ng-container *ngFor="let filter of filterConfig">
                <ng-container [ngSwitch]="filter.type">
                    <ng-container *ngSwitchCase="filterTypes.date">
                        <app-date-selection-widget
                                *ngIf="filter.type === filterTypes.date"
                                [title]="filter.label"
                                [minDate]="filter.dataKey !== 'expiryDate' ? minDate : null"
                                [dateFilter]="filter.dateSignal || dateFilter"
                                [useReactiveFilters]="true"
                                [displayOnlyFutureDates]="false"
                                [displayOnlyPastDates]="true"
                                [displayIncludingToday]="true"
                                (datesEventNew)="filter.onAction($event)"
                                [readonly]="bulkModeActive()"
                        ></app-date-selection-widget>
                    </ng-container>
                    <ng-container *ngSwitchCase="filterTypes.trueFalse">
                        <div class="toggle-wrapper">
                            <ion-label i18n class="filter-label">{{filter.label}}</ion-label>
                            <ion-toggle mode="ios" [checked]="filters()[filter.dataKey]" (ionChange)="filter.onAction($event, filter.dataKey)"></ion-toggle>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="filterTypes.select">
                        <div class="filter" id="filter-trigger-{{tableType}}-{{filter.dataKey}}">
                            <div class="filter-label" i18n>{{filter.label}}</div>
                            <div class="filter-text" [class.disabled]="bulkModeActive()" i18n>
                                @if(filter.selectableValues) {
                                    {{filter.selectableValues | getValueById:{id: filters()[filter.dataKey], field: 'shortcode', altField: 'title'} }}
                                } @else {
                                    {{filter.selectableSignal() | getValueById:{id: filters()[filter.dataKey], field: 'shortcode', altField: 'title'} }}
                                }

                                <ion-icon name="caret-down-sharp" *ngIf="!bulkModeActive()"></ion-icon>
                            </div>
                        </div>
                        <ion-popover trigger="filter-trigger-{{tableType}}-{{filter.dataKey}}" dismissOnSelect="true" *ngIf="!bulkModeActive()">
                            <ng-template>
                                @if(filter.selectableValues) {
                                    <app-select-popover [options]="filter.selectableValues" (itemClicked)="filter.onAction($event, filter.dataKey)"/>
                                } @else {
                                    <app-select-popover [options]="filter.selectableSignal()" (itemClicked)="filter.onAction($event, filter.dataKey)"/>
                                }
                            </ng-template>
                        </ion-popover>
                    </ng-container>
                    <ng-container *ngSwitchCase="filterTypes.multiSelect">
                        <div class="filter" id="filter-trigger-{{tableType}}-{{filter.dataKey}}">
                            <div class="filter-label" i18n>{{filter.label}}</div>
                            <div class="filter-text" [class.disabled]="bulkModeActive()" i18n>
                                {{filter | filterResultValue:filters()[filter.dataKey] }}
                                <ion-icon name="caret-down-sharp" *ngIf="!bulkModeActive()"></ion-icon>
                            </div>
                        </div>
                        <ion-popover trigger="filter-trigger-{{tableType}}-{{filter.dataKey}}" dismissOnSelect="false" *ngIf="!bulkModeActive()">
                            <ng-template>
                                <app-select-popover
                                        [useCheckboxes]="true"
                                        [options]="filter.selectableValues | filterExcluded"
                                        [selected]="filters()[filter.dataKey]"
                                        (itemClicked)="filter.onAction($event, filter.dataKey)"/>
                            </ng-template>
                        </ion-popover>
                    </ng-container>
                    <ng-container *ngSwitchCase="filterTypes.inputPopover">
                        <app-input-popover
                                [data]="filter.selectableSignal"
                                [search]="filter.searchSignal"
                                maxHeightDropdown="250px"
                                [class.limited-height]="limitedInputPopoverHeight"
                                [placeholder]="'Rechnungsnummer'" i18n-placeholder
                                searchIconEnd="true"
                                (onItemSelected)="filter.onAction($event, filter.dataKey)"
                                (searchChange)="filter.onSearch($event, filter.dataKey)"
                        />
                    </ng-container>
                    <ng-container *ngSwitchCase="filterTypes.search">
                        <ng-container *ngIf="!bulkModeActive()">
                            <div class="search-container" (click)="triggerSearchModal($event, true)">
                                <div class="search-button">
                                    <div class="placeholder" *ngIf="searchFieldText()?.length <= 0; else searchText" i18n>Suche</div>
                                    <ng-template #searchText>
                                        <div class="search-text">{{searchFieldText()}}</div>
                                    </ng-template>
                                    <ion-icon src="assets/icons/cancel.svg" (click)="clearFilter()" *ngIf="searchFieldText()" mode="ios" slot="end" class="clickable"></ion-icon>
                                    <ion-icon name="search" mode="ios" slot="end" *ngIf="!searchFieldText()"></ion-icon>
                                </div>
                                <ion-button class="filter-button" [class.active]="searchFieldText()" fill="clear">
                                    <ion-icon name="filter-outline" color="medium"></ion-icon>
                                </ion-button>
                            </div>
                            <!--###################### search modal template ######################-->
                            <ion-modal [isOpen]="isSearchModalOpen" class="modal-large" (ionModalWillDismiss)="searchModalWillDismiss()">
                                <ng-template>
                                    <app-search-modal [filters]="filters" [filterConfig]="searchModalConfig" [minDate]="minDate"/>
                                </ng-template>
                            </ion-modal>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div class="right-inner-container">
            <ng-container *ngIf="!bulkModeActive()">
                <ion-button *ngIf="bulkModeAvailable" class="square-button bulk-select button-tertiary"
                            appTooltip ttp="bottom" ttk="bulk_selection"
                            (click)="changeBulkActionMode(true)">
                    <ion-icon name="square-outline"></ion-icon>
                    <ion-icon name="caret-down-sharp"></ion-icon>
                </ion-button>
                <ng-container *ngFor="let filter of filterRightConfig">
                    <ng-container [ngSwitch]="filter.type">
                        <ng-container *ngSwitchCase="filterTypes.info">
                            <ion-button class="square-button button-tertiary"
                                        (click)="filter.onAction($event)"
                                        appTooltip ttp="bottom" ttk="{{filter?.ttk}}">
                                <ion-icon [name]="filter?.icon"/>
                            </ion-button>
                        </ng-container>
                        <ng-container *ngSwitchCase="filterTypes.print">
                            <ion-button class="square-button button-tertiary"
                                        [class.disabled]="!filter?.payload?.isEnabled() && !filter?.payload?.isLoading()"
                                        (click)="filter?.payload?.isEnabled() && !filter?.payload?.isLoading() ? filter.onAction($event) :null"
                                        appTooltip ttp="bottom" ttk="{{filter?.payload?.isEnabled() ? 'print_list_enabled' : filter?.payload?.isLoading() ? 'print_list_loading' : 'print_list_disabled'}}">
                                <ion-icon [name]="filter?.icon" *ngIf="!filter?.payload?.isLoading()" />
                                <ion-spinner *ngIf="filter?.payload?.isLoading()"></ion-spinner>
                            </ion-button>
                        </ng-container>
                        <ng-container *ngSwitchCase="filterTypes.download">
                            <ion-button id="export_button_{{filter?.payload?.trigger}}_{{tableType}}" class="square-button button-tertiary" >
                                <ion-icon *ngIf="!filter?.payload?.isLoading()" [name]="filter?.icon"/>
                                <ion-spinner *ngIf="filter?.payload?.isLoading()" />
                            </ion-button>
                            <ion-popover trigger="export_button_{{filter?.payload?.trigger}}_{{tableType}}" triggerAction="hover" show-backdrop="false" dismissOnSelect="true">
                                <ng-template>
                                    <ion-list>
                                        <ion-item detail="false" lines="none" button="true"
                                                  *ngFor="let option of exportConfig"
                                                  (click)="filter.onAction(option.type)">
                                            {{option.label}}
                                        </ion-item>
                                    </ion-list>
                                </ng-template>
                            </ion-popover>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>


<!--###################### no results ######################-->
<ng-template #tableNoResults>
    <tr class="no-results-row">
        <td [attr.colspan]="columns.length">
            <div class="no-results">
                @if(statusErrorMessage && statusErrorMessage() !== '') {
                    <h1 [innerHTML]="statusErrorMessage()"></h1>
                } @else {
                    <h1 i18n>Es konnten keine Treffer gefunden werden. <br />Verändern Sie ggf. die Suchkriterien.</h1>
                }
                <div class="no-results-filters">
                    <ng-container *ngIf="filters()">
                        <ng-container  *ngFor="let filter of noResultFilters()">
                            <app-badge [badgeType]="filterBadgeType" code='{{filter?.label}} &bdquo;{{filter?.value}}&ldquo;'></app-badge>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="no-results-action">
                    <ng-container *ngFor="let filter of noResultFilters(); trackBy:trackBy">
                        <ng-container *ngIf="filter.type === filterTypes.date">
                            <app-date-selection-widget
                                    [title]="'Zeitraum für ' + filter?.label + ' anpassen'"
                                    [minDate]="filter.dataKey !== 'expiryDate' ? minDate : null"
                                    [dateFilter]="searchModalConfig ? (searchModalConfig.items | find: filter.dataKey: 'dataKey')['dateSignal'] : dateFilter"
                                    [useReactiveFilters]="true"
                                    [displayOnlyFutureDates]="false"
                                    [displayOnlyPastDates]="true"
                                    [displayIncludingToday]="true"
                                    [displayButtonInsteadOfDateRange]="true"
                                    (datesEventNew)="filter?.onAction($event)"
                            ></app-date-selection-widget>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="searchModalConfig && !bulkModeActive()">
                        <ion-button id="no-results-search-modal-trigger_{{tableType}}" i18n>Filterkriterien ändern</ion-button>
                        <ion-modal trigger="no-results-search-modal-trigger_{{tableType}}" class="modal-large" (ionModalWillDismiss)="searchModalWillDismiss()">
                            <ng-template>
                                <app-search-modal [filters]="filters" [filterConfig]="searchModalConfig" [minDate]="minDate"/>
                            </ng-template>
                        </ion-modal>
                    </ng-container>
                </div>
            </div>
        </td>
    </tr>
</ng-template>

<!--###################### default filters template ######################-->
<ng-template #bulkTemplateRef>
    <div class="display-flex filters-container bulk-filters-container">
        <div>
            <span class="display-flex-column">
                <ion-label class="filter-label" i18n>Aktion</ion-label>
                <app-badge id="bulk-action-popover_{{tableType}}"
                           [badgeType]="bulkActionBadgeType"
                           [code]="bulkSelectAction()"
                           isClickable="true"
                           [icon]="'caret-down-sharp'"></app-badge>
            </span>
            <ion-popover trigger="bulk-action-popover_{{tableType}}" triggerAction="click" dismissOnSelect="true">
                <ng-template>
                    <ion-list>
                        <ion-item lines="none" button="true"
                                  *ngFor="let action of bulkActionConfig"
                                  (click)="bulkActionSelected(action.code)">
                            {{action.label}}
                        </ion-item>
                    </ion-list>
                </ng-template>
            </ion-popover>
        </div>
        <div class="bulk-filters-inner-container display-flex">
            <ng-container *ngTemplateOutlet="filtersOutput"></ng-container>
        </div>
        <div class="display-flex bulk-action-button-container ion-align-items-center">
            <ion-text *ngIf="bulkSelection().length > 0" class="bulk-selection-count" i18n>{{bulkSelection().length}} Aufträge ausgewählt</ion-text>
            <ion-button *ngIf="bulkActionLabel !== bulkActionDefaultLabel" (click)="submitBulkAction()" color="primary">{{bulkActionLabel}}</ion-button>
            <ion-button class="button-tertiary ion-margin-start" (click)="changeBulkActionMode(false)" i18n>Abbrechen</ion-button>
        </div>
    </div>
</ng-template>

<ng-template #filtersOutput>
    <ng-container *ngTemplateOutlet="filterTemplateRef" />
    <ng-container *ngIf="!filterTemplateRef">
        <ng-container *ngTemplateOutlet="defaultFilters" />
    </ng-container>
</ng-template>
<!--###################### initial loading table template ######################-->
<ng-template #initialLoadingTemplate>
    <div class="default-loader">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
    </div>
</ng-template>
