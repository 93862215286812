export enum CommunicationZoneFilterEnum {
    allRequestsUnsolved = 'allRequestsUnsolved',
    allRequestsSolved = 'allRequestsSolved',
    myRequests = 'myRequests'
}

export enum CommunicationZoneReferenceTypeEnum {
    LS_DOCUMENT = 'LS_DOCUMENT',
    CH_DOCUMENT = 'CH_DOCUMENT',
    RE_DOCUMENT = 'RE_DOCUMENT',
    RETURNS = 'RETURNS',
    ORDERS = 'ORDERS'
}

export enum CommunicationZoneType {
  ORDER = 'order',
  RETURN = 'return',
  DOCUMENT_CH = 'documentCH',
  DOCUMENT_LS = 'documentLS',
  DOCUMENT_ESR = 'documentESR',
  DOCUMENT_ETR = 'documentETR',
  DOCUMENT_SB = 'documentSB',
  MEA_CHAT = 'meaChat',
  MEA_COOP = 'meaCoop',
  USER_DATA = 'userData',
  PHARMACY_DATA = 'pharmacyData',
  FORM = 'form',
  OFFER = 'offer',
  DISPO_ORDER = 'dispoOrder',
  DISPO_REORDER = 'dispoReorder',
  GENERAL = 'general',
  REQUEST = 'request',
  DATA_CHANGE = 'dataChange',
}

// enum for badge/filter groups
export enum CommunicationZoneGroup {
  ALL = 'all',
  GENERAL = 'general',
  REQUEST = 'request',
  DATA_CHANGE = 'dataChange',
  INVOICE = 'invoice',
  DELIVERY = 'delivery',
  OFFER = 'offer',
  FORM = 'form',
  RETURN = 'return',
  ORDER = 'order',
}
