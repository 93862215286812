import { ChangeDetectionStrategy, Component, inject, Input, Signal } from '@angular/core';
import { NoteWidgetComponent } from '../../../../../../widgets/note-widget/note-widget.component';
import { NotesInterface, ReturnsDetailInterface, TableSidebarInterface } from '../../../../../../core/core.interfaces';
import { ModalService } from '../../../../../../core/core.services';
import {
    CommunicationZoneType,
    ModalClassEnum,
    NotesTypeEnum
} from '../../../../../../core/core.enums';
import { CommunicationZoneFormComponent } from '../../../../../communications/pages/communication-zone/widgets/communication-zone-form/communication-zone-form.component';
import { getReturnsReplacementArray } from '../../../../../../core/config/communication-zone-forms.config';


@Component({
    selector: 'app-returns-view',
    templateUrl: './returns-view.component.html',
    styleUrls: ['./returns-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnsViewComponent {
    static modalClass = ModalClassEnum.large;
    private modalService = inject(ModalService);

    @Input({required: true}) details: Signal<ReturnsDetailInterface>;
    @Input({required: true}) sidebar: Signal<TableSidebarInterface>;
    @Input({required: true}) notesById: Signal<NotesInterface>;


    /**
     * opens the copy modal
     */
    async onSupportButtonClick() {
        await this.modalService.dismiss();

        const modal = await this.modalService.create(
            CommunicationZoneFormComponent,
            {
                id: this.details().id,
                type: CommunicationZoneType.RETURN,
                isReadOnly: true,
                replaceArray: getReturnsReplacementArray(false, this.details())
            }
        );
        await this.modalService.present(modal);
    }

    /**
     * Opens modal for create/editing a note
     */
    async onRecordNoteClick() {
        const modal = await this.modalService.create(
            NoteWidgetComponent,
            {
                sourceId: this.details().id,
                noteType: NotesTypeEnum.returns
            }
        );

        await this.modalService.present(modal);
    }
}

