import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { isEqual } from 'lodash';
import { Howl } from 'howler';

import { NotificationsSettingInterface } from '../interfaces/settings.interface';
import { SettingsQueries } from '../store/graphql/queries/settings.graphql';
import {
    FullMeaRoutesEnum,
    NotificationGeneralTypeEnum,
    NotificationsEnum,
    NotificationSoundsEnum,
} from '../core.enums';
import { unsubscribe, unsubscribeAll } from '../util/subscriptions.util';

interface NotificationTypeValuesInterface {
    title?: string;
    body?: string;
}


@Injectable({
    providedIn: 'root',
})

export class NotificationSoundService {

    // The IDs of the intervals need to be static to be the same  in every object instance. This enables interval clearing
    // outside of this service, e.g. in login.page via login.service. It also prevents multiple intervals per use case.
    static notificationInterval: {
        [key in NotificationGeneralTypeEnum]: number
    } = {
        [NotificationGeneralTypeEnum.COMMON]: null,
        [NotificationGeneralTypeEnum.CHAT]: null
    };

    static notification: {
        [key in NotificationGeneralTypeEnum]: NotificationTypeValuesInterface
    } = {
        [NotificationGeneralTypeEnum.COMMON]: null,
        [NotificationGeneralTypeEnum.CHAT]: null
    };

    static notificationSettings: NotificationsSettingInterface;

    constructor(
        private settingsQueries: SettingsQueries,
        private router: Router
    ) {
    }

    notificationSubscription: Subscription;
    routerSubscription: Subscription;

    disableMeaChatNotifications = false;

    player: Howl;
    players: {[key in NotificationSoundsEnum]: Howl};

    /**
     * clear interval as static function using a static interval ID
     */
    static staticClearInterval(type: NotificationsEnum = null) {
        if(type) {
            switch(type) {
                case NotificationsEnum.NEW_MEACHATMESSAGE:
                    clearInterval(NotificationSoundService.notificationInterval.chat);
                    break;
                default:
                    clearInterval(NotificationSoundService.notificationInterval.common);
                    break;
            }
        } else {
            clearInterval(NotificationSoundService.notificationInterval.chat);
            clearInterval(NotificationSoundService.notificationInterval.common);
        }
    }

    initNotificationSoundPlayer() {
        this._initPlayers();
        this._getSettings();

        // Disable mea chat notifications, if the user is on the mea chat page.
        unsubscribe(this.routerSubscription);
        this.routerSubscription = this.router.events
            .pipe(filter( event => event instanceof NavigationEnd))
            .subscribe((event: Event|RouterEvent) => {
                if (event instanceof RouterEvent && event.url) {
                    this.disableMeaChatNotifications = event.url === FullMeaRoutesEnum.chat;
                }
            });
    }


    destroy() {
        Object.keys(NotificationSoundService.notificationInterval).forEach(key => {
            // Clear existing interval
            if (NotificationSoundService.notificationInterval[key]) {
                clearInterval(NotificationSoundService.notificationInterval[key]);
            }
            if(NotificationSoundService.notification[key]) {
                NotificationSoundService.notification[key] = null;
            }
        });
        this.clearAllSubscribers();
    }


    /**
     * Set a new notification by type
     *
     * @param type
     * @param payload
     */
    newNotification(
        type: NotificationsEnum = NotificationsEnum.NEW_MAINTENANCE,
        payload: NotificationTypeValuesInterface = {
            title: 'Sanacorp Connect',
            body: ''
        }
    ) {
        switch(type) {
            case NotificationsEnum.NEW_MEACHATMESSAGE:
                NotificationSoundService.notification.chat = payload;
                if (window.location.pathname !== FullMeaRoutesEnum.chat) {
                    this._checkNotifications('chat');
                }
                break;
            default:
                NotificationSoundService.notification.common = payload;
                this._checkNotifications('common');
                break;
        }
    }

    /**
     * Stops all subscriptions
     */
    clearAllSubscribers = () => {
        unsubscribeAll([
            this.notificationSubscription
        ]);
    };



    // ==========================================================================================
    // Private
    // ==========================================================================================

    private _initPlayers() {
        this.players = {
            [NotificationSoundsEnum.DEFAULT]: new Howl({src: ['assets/sounds/sanacorp_earcon.mp3']}),
            [NotificationSoundsEnum.CHAT]: new Howl({src: ['assets/sounds/notification.mp3']}),
        };
        this.player = this.players[NotificationSoundsEnum.DEFAULT];
    }

    /**
     * Load settings and listen for changes
     * @private
     */
    private _getSettings() {
        unsubscribe(this.notificationSubscription);
        this.notificationSubscription = this.settingsQueries.getSoundNotificationSettings()
            .subscribe((notificationSettings: NotificationsSettingInterface) => {
                if(!isEqual(NotificationSoundService.notificationSettings, notificationSettings)) {
                    NotificationSoundService.notificationSettings = notificationSettings;
                    this._startIntervals();
                }
            });
    }

    /**
     * (Re-)Start intervals to check notifications
     *
     * @private
     */
    private _startIntervals() {
        Object.keys(NotificationSoundService.notificationInterval).forEach(key =>{
            // Clear existing interval
            if(NotificationSoundService.notificationInterval[key]) {
                clearInterval(NotificationSoundService.notificationInterval[key]);
            }
            if(
                NotificationSoundService.notificationSettings[key].soundEnabled
                &&
                NotificationSoundService.notificationSettings[key].soundInterval
            ) {
                // Start new interval
                NotificationSoundService.notificationInterval[key] = setInterval(_ => {
                    this._checkNotifications(key);
                }, NotificationSoundService.notificationSettings[key].soundInterval * 1000);
            }
        });
    }

    /**
     * Check if there is a notification available, play the sound.
     * If the user is on the mea chat page, the sound will not be played.
     * @param key
     * @private
     */
    private _checkNotifications(key){
        if(NotificationSoundService.notification[key]
            && NotificationSoundService.notificationSettings
            && NotificationSoundService.notificationSettings[key]?.soundEnabled
            && !this.disableMeaChatNotifications
        ) {
            this._playSound(key === 'chat' ? NotificationSoundsEnum.CHAT : NotificationSoundsEnum.DEFAULT);
        }
    }

    /**
     * Play notification sounds
     */
    private _playSound(soundId: NotificationSoundsEnum = NotificationSoundsEnum.DEFAULT, isRetry = false) {
        let isPlaying = 0;
        // Stop all players
        Object.keys(this.players).forEach(playerKey => {
            const player = this.players[playerKey];
            if(player.playing() && playerKey === soundId) {
                isPlaying = -1;
                return;
            }

            if(player.playing() && !isRetry) {
                // Timeout for retry
                isPlaying = (player.duration() * 1000) + 200;
                return;
            }
            player.stop();
        });

        // Prevent the same player to play again immediately
        if(isPlaying === -1) {
            return;
        }

        // Retry after three seconds to prevent multiple playing sounds at once
        if (isPlaying) {
            setTimeout(() => {
                this._playSound(soundId, true);
            },isPlaying);
            return;
        }

        // Select a specific player
        this.player = this.players[soundId];
        try {
            this.player.play();

            // TODO - Maybe remove console.info later
            // tslint:disable-next-line:no-console
            console.info(new Date().toLocaleTimeString() + ' - Play notification sound ' + soundId, isRetry);
        } catch(exception) {
            console.error('Failed to play notification sound');
        }
    }
}
