import { gql } from '@apollo/client/core';
import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';



export const SendFormMutation = gql`
    mutation SendForm($id: Int!, $formFields: [FormFields]!) {
        SendForm(id: $id, formFields: $formFields){
            status
            message
        }
    }
`;



export const AllFormsMutations = [
    SendFormMutation
];

@Injectable()
export class FormsMutations {
    private apollo = inject(Apollo);
    private apiService = inject(ApiService);
    sendForm(id: number, formFields: any) {
        return new Promise( (resolve, reject) => {
            this.apollo.mutate({
                mutation: SendFormMutation,
                variables: { id, formFields },
            }).subscribe({
                next: result => {
                    from([result])
                        .pipe(map(d => d?.data && d?.data['SendForm'] && d?.data['SendForm']['status']))
                        .subscribe((status: string) => {
                            if (!status || status === 'ERROR' || !!result['errors']) {
                                void this.apiService.presentErrorToast(result['errors'], 'Das Formular konnte nicht gespeichert werden.');
                                resolve(false);
                            } else {
                                void this.apiService.presentSuccessToast('Das Formular wurde erfolgreich abgesendet.');
                                resolve(true);
                            }
                        });
                },
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Das Formular konnte nicht gespeichert werden.');
                    reject(error);
                }
            });
        });
    }

}
