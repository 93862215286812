import { Injectable, inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { PharmacyStoreStateVar } from '../store/locals/pharmacyStoreState.var';
import { AuthQueries } from '../store/graphql/queries/auth.graphql';
import { PharmacyStoreInterface } from '../interfaces/customer.interface';
import { unsubscribe, unsubscribeAll } from '../util/subscriptions.util';

@Injectable({
    providedIn: 'root',
})
export class PharmacyStoreService {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    constructor(private authQueries: AuthQueries) {}
    pharmacyStoreSubscription: Subscription;

    /**
     * Stops all subscriptions
     */
    clearAllSubscribers = () => {
        unsubscribeAll([this.pharmacyStoreSubscription]);
    };

    /**
     * call notification query
     */
    public updateDataChanged(pollOften = false): Promise<void> {
        return new Promise((resolve) => {
            unsubscribe(this.pharmacyStoreSubscription);
            this.pharmacyStoreSubscription = this.authQueries
                .getPharmacyStoresByPolling(pollOften)
                .subscribe(async (pharmacyStores: Array<PharmacyStoreInterface>) => {
                    if ((!pharmacyStores || pharmacyStores.length === 0) && pollOften === false) {
                        await this.updateDataChanged(true);
                        resolve();
                        return;
                    } else if (pharmacyStores && pharmacyStores.length > 0) {
                        const hasPharmacyUninitialized = !!pharmacyStores.find((pharmacyStore) => !pharmacyStore.firstInitialized);
                        if (hasPharmacyUninitialized !== pollOften) {
                            await this.updateDataChanged(hasPharmacyUninitialized);
                            resolve();
                            return;
                        }

                        this.pharmacyStoreStateVar.set(pharmacyStores.filter((pharmacyStore) => pharmacyStore.firstInitialized));
                    }
                    resolve();
                });
        });
    }
}
