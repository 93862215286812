import { EventEmitter, Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { BroadcastChannel } from 'broadcast-channel';
import { v4 as uuid } from 'uuid';
import * as Sentry from '@sentry/angular';

/**
 * Authentication Service via OAuth2
 *
 * Library Documentation: https://manfredsteyer.github.io/angular-oauth2-oidc/docs/index.html
 *
 *
 * IMPORTANT: DO NOT IMPORT IN CORE MODULE OR ANY OTHER PROVIDER
 */
@Injectable({
    providedIn: 'root',
})
export class SSOAuthenticationService {

    constructor(
        private oAuthService: OAuthService
    ) {
        this.tabBroadcast = new BroadcastChannel('tab');
        this.initTabBroadCast();
    }

    storagePrefix = '';
    windowRef = null;
    public hasAccessToken = new EventEmitter<boolean>();
    private authPromise: Promise<boolean> | null = null;
    private tabBroadcast: BroadcastChannel;
    private tabLocked = false;
    private guid: string;

    /**
     * Calculates sizes of the popup
     * @param options
     * @private
     */
    static calculatePopupFeatures(options?: {
        height?: number;
        width?: number;
    }) {
        options = options || {};
        // Specify an static height and width and calculate centered position
        const height = options.height || 600;
        const width = options.width || 500;
        const left = window.screenLeft + (window.outerWidth - width) / 2;
        const top = window.screenTop + (window.outerHeight - height) / 2;
        return `location=no,toolbar=no,width=${width},height=${height},top=${top},left=${left}`;
    }

    /**
     * Broadcast events between tabs
     * @private
     */
    private initTabBroadCast() {
        if(this.guid)
            return;
        this.guid = uuid();
        this.tabBroadcast.addEventListener('message', (msg) => {
            if(!msg || !msg.data || msg.data.guid === this.guid) {
                return;
            }
            if (msg.data.msg === 'start-auth') {
                // message received from 2nd tab
                this.tabLocked = true;
            }
            if (msg.data.msg === 'finish-auth') {
                // message received from 2nd tab
                this.tabLocked = false;
                if(msg.data.newToken !== null) {
                    this.hasAccessToken.emit(msg.data.newToken);
                }
            }
        });
    }

    public async configure(config: AuthConfig,  storagePrefix: string = 'sconnect_') {
        // Make sure that no auth promise of another instance is active before running the config
        let i = 0;
        while (this.storagePrefix !== storagePrefix && this.authPromise !== null) {
            await new Promise((resolve) => setTimeout(resolve, 100)); // Wait 100ms
            if(++i === 50) break; // Wait a maximum of 5 seconds
        }

        if(this.storagePrefix !== storagePrefix) {
            this.storagePrefix = storagePrefix;
            this.oAuthService.configure(config);
            this.oAuthService.setStorage({
                getItem: (key: string): string | null => {
                    return localStorage.getItem(this.storagePrefix + key);
                },
                setItem: (key: string, data: string) => {
                    localStorage.setItem(this.storagePrefix + key, data);
                },
                removeItem: (key: string) => {
                    localStorage.removeItem(this.storagePrefix + key);
                }
            });
        }
    }
    /**
     * Loads the discovery document if required
     */
    private async initDiscoveryDocument(){
        await this.oAuthService.loadDiscoveryDocument();
    }

    public openLoginTab() {
        this.windowRef = window.open('about:blank', '_blank', SSOAuthenticationService.calculatePopupFeatures());
        return !!this.windowRef;
    }

    /**
     * Initializes the login flow in a popup
     * @returns Promise<unknown> returns a promise on success
     */
    public async initLoginFlow(inWindow : boolean = true): Promise<boolean> {
        return new Promise(resolve => {
            this.getAccessToken().then(async hasTokenAlready => {
                if(hasTokenAlready) {
                    resolve(true);
                }else {
                    await this.initDiscoveryDocument();
                    if(inWindow) {
                        this.oAuthService.initLoginFlowInPopup({windowRef: this.windowRef}).then(_ => {
                            return this.getAccessToken().then(hasTokenReceived => {
                                resolve(hasTokenReceived);
                            });
                        }).catch(async () => {
                            resolve(false);
                        });
                    } else {
                        this.oAuthService.initLoginFlow();
                        resolve(null);
                    }
                }
            });
        });
    }

    public async tryResolveLogin() : Promise<any> {
        try {
            await this.initDiscoveryDocument();
            const success = await this.oAuthService.tryLogin();

            if (!success) {
                return success;
            }

            return await this.getAccessToken();
        } catch (e) {
            return false;
        }
    }

    /**
     * Returns the access token - requests new if old one is already expired
     * @returns Promise<string>
     */
    public async getAccessToken(forceRefresh = false): Promise<boolean> {
        if(this.authPromise !== null) {
            return this.authPromise;
        }
        this.authPromise = new Promise(async resolve => {

            let i = 0;
            while (this.tabLocked) {
                await new Promise((r) => setTimeout(r, 100));
                if(++i === 50) break; // Wait at max 5s for the lock to release
            }
            void this.tabBroadcast.postMessage({msg: 'start-auth', guid: this.guid});

            if((!this.oAuthService.hasValidAccessToken() || forceRefresh) && this.oAuthService.getRefreshToken()) {
                try {
                    await this.initDiscoveryDocument();
                    await this.oAuthService.refreshToken();
                } catch (e) {
                    // this shouldn't happen but in case it does, it is better to logout the user
                    // otherwise the meamind page will load endlessly and cannot be used anymore
                    if (e.error && e.error.error === 'invalid_grant' && e.error.error_description === 'Stale token') {
                        resolve(null);
                        return;
                    } else {
                        Sentry.captureException(e);
                        resolve(null);
                        return;
                    }
                }
            }

            const token = this.oAuthService.getAccessToken();

            if(token) {
                this.hasAccessToken.emit(true);
                resolve(true);
            } else {
                this.hasAccessToken.emit(false);
                resolve(false);
            }
        });
        // Catch exception thrown
        this.authPromise.then((newToken) => {
            this.tabBroadcast.postMessage({msg: 'finish-auth', guid: this.guid, newToken});
            this.authPromise = null;
        });
        return this.authPromise;
    }

    public getIdToken(): string | null {
        return this.oAuthService.getIdToken();
    }
}
