import { computed, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Storage } from '@ionic/storage-angular';
import { map, Observable, switchMap, tap } from 'rxjs';
import isEqual from 'lodash/isEqual';

import { defaultMeaDownloadsFilters } from '../config/mea.config';
import { MeaDownloadsStorageKeys, MeaDownloadTypes } from '../enums/mea.enum';
import { MeaQueries } from '../store/graphql/queries/mea.graphql';
import { TableFieldsEnum, TableFilterTypeEnum } from '../enums/table.enum';
import { AuthStorageKeyEnum } from '../enums/authStorageKey.enum';
import { SortDirectionEnum } from '../enums/sort-direction.enum';
import { ButtonTypes } from '../enums/button-actions.enum';
import { BadgeTypeEnum } from '../enums/badge.enum';
import {
    TableColumnInterface,
    TableFilterConfigInterface,
    TableSearchModalConfigInterface,
    TableSidebarItemsInterface, TableSortInterface
} from '../interfaces/table.interface';
import { MeaDownloadsDataInterface, MeaDownloadsFiltersInterface, MeaDownloadTypesInterface } from '../interfaces/mea.interface';
import { DateSelectionEventInterface, DateSelectionFilterInterface } from '../interfaces/date-range.interface';
import { DateRangeConfig } from '../config/date-range.config';
import { formatDateTimeToDate } from '../formatting/date.formatting';
import { ModalService } from './modal.service';
import { PdfProviderService } from './pdfProvider.service';
import {
    MeaDownloadsInfoModalComponent
} from '../../pages/mea/pages/mea-downloads/components/mea-downloads-info-modal/mea-downloads-info-modal.component';

@Injectable({
    providedIn: 'root',
})
export class MeaDownloadsService {
    private storage = inject(Storage);
    private meaQueries = inject(MeaQueries);
    private modalService = inject(ModalService);
    private pdfService = inject(PdfProviderService);

    constructor() {
        const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);

        this.storage.get(`${MeaDownloadsStorageKeys.FILTERS}_${activePharmacy}`).then((filters: MeaDownloadsFiltersInterface) => {
            if (filters) {
                this._filters.set(filters);
            }
        });
        this.storage.get(`${MeaDownloadsStorageKeys.SORT}_${activePharmacy}`).then((sort: TableSortInterface) => {
            if (sort) {
                this._sort.set(sort);
            }
        });


        /**
         * Save the filters to the storage as soon as they change
         */
        effect(() => {
            if(this._filters()) { // required, so the effect gets triggered
                this.storage.get(`${MeaDownloadsStorageKeys.FILTERS}_${activePharmacy}`).then((filters) => {
                    if (!isEqual(filters, this._filters())) {
                        void this.storage.set(`${MeaDownloadsStorageKeys.FILTERS}_${activePharmacy}`, this._filters());
                    }
                });
            }
        });

        /**
         * Save the sorting to the storage as soon as it changes
         */
        effect(() => {
            if(this._sort()) { // required, so the effect gets triggered
                this.storage.get(`${MeaDownloadsStorageKeys.SORT}_${activePharmacy}`).then((sort) => {
                    if (!isEqual(sort, this._sort())) {
                        void this.storage.set(`${MeaDownloadsStorageKeys.SORT}_${activePharmacy}`, {
                            field: this._sort().field,
                            order: this._sort().order
                        });
                    }
                });
            }
        });

        /**
         * Change the sort of the mea downloads, when sort direction is changed.
         */
        effect(() => {
            this._sort();
            let sorted: MeaDownloadsDataInterface[] = [];
            untracked(() => {
                sorted = this.sortData();
                this._downloads.set(sorted);
            });
        });
    }

    public readonly columns: TableColumnInterface[] = [
        {id: 1, title: 'Name',      width: '10rem', class:'col-auto', sortable: true, dataKey: TableFieldsEnum.label},
        {id: 2, title: 'Datum',     width: '12rem', sortable: true, dataKey: TableFieldsEnum.createdAt},
        {id: 3, title: 'Format',    width: '12rem', sortable: true, dataKey: TableFieldsEnum.file},
        {id: 4, title: 'Typ',       width: '15rem', sortable: false, dataKey: TableFieldsEnum.downloadTypes, class: 'columnType'},
        {id: 5, title: 'Aktionen',  width: '10rem', sortable: false},
    ];

    private filterConfig = defaultMeaDownloadsFilters;
    private _selectedId = signal<string>(null);
    public selectedId = this._selectedId.asReadonly();

    private _isLoading = signal<boolean>(true);
    public isLoading = this._isLoading.asReadonly();

    private _filters = signal<MeaDownloadsFiltersInterface>(this.filterConfig);
    public filters = this._filters.asReadonly();
    public dateFilter = computed<DateSelectionFilterInterface>(() => {
        const filters = this.filters();
        return {
            label: 'Datum',
            selectedValue: filters?.dateOption,
            selectedValues: {
                dateOption: filters?.dateOption,
                dateRangeOptions: DateRangeConfig,
                dateFrom: filters?.dateFrom,
                dateTo: filters?.dateTo,
            },
        };
    });

    private _sort = signal<TableSortInterface>({
        field: TableFieldsEnum.label,
        order: SortDirectionEnum.desc,
        onUpdate: (field: TableFieldsEnum) => {
            this.updateSort(field);
        }
    });
    public sort = this._sort.asReadonly();

    private _downloads = signal<MeaDownloadsDataInterface[]>([]);
    public downloads = this._downloads.asReadonly();
    private downloads$ = toObservable(this._filters).pipe(
        switchMap(filters => {
            return this.meaQueries.getMeaDownloads(filters);
        }),
        tap(data => {
            this._downloads.set(data || []);
            if (data?.length) {
                // we need to set null first, to change the download types if the filter changes (if the first download has multiple download types)
                this._selectedId.set(null);
                this._selectedId.set(data[0].id);
            } else {
                this._selectedId.set(null);
            }
            this._isLoading.set(false);
        })
    );
    // toSignal automatically subscribes and unsubscribes to the observable
    downloadsReadOnly = toSignal(this.downloads$, {initialValue: [] as MeaDownloadsDataInterface[]});

    private types$ =  this.meaQueries.getMeaDownloadTypes().pipe(
        map(d => {
            // Map type to title, because it is required by the filter
            const items = d.map(type => ({...type, id: type.type, title: type.type}));
            items.unshift({id: '', type: MeaDownloadTypes.ALL, title: MeaDownloadTypes.ALL, color: ''});
            return items;
        })
    );
    public types = toSignal(this.types$, {initialValue: []});

    public sidebar = computed(() => {
        const download = this.selectedDownload();
        if(download) {
            return {
                title: download.label,
                actionPopoverItems: [
                    {label: 'Drucken', code: ButtonTypes.PRINT, onAction: () => this.onPrint(), isDisabled: download.file.split('.').pop() !== 'pdf'},
                    {label: 'Download', code: ButtonTypes.DOWNLOAD, onAction: () => this.onDownload()},
                ],
                sidebarItems: this.buildSidebarItems(download),
                previousRow: () => this.updateActiveId(-1),
                nextRow: () => this.updateActiveId(1),
                hasEditButton: false
            };
        }
        return null;
    });


    public selectedDownload = computed(() => {
        const selectedId = this._selectedId();

        return untracked(() => {
            const downloads = this._downloads();
            if (!downloads?.length) {
                return null;
            }
            if (!selectedId) {
                this._selectedId.set(downloads[0].id);
                return;
            }
            const selectedDownload = downloads.find(d => d.id === selectedId);
            if (selectedDownload) {
                return selectedDownload;
            }
            this._selectedId.set(downloads[0].id);
        });
    });

    /**
     * Configure the filters for the returns table
     */
    public filterLeftConfig: TableFilterConfigInterface[] = [
        {
            label: 'Datum',
            type: TableFilterTypeEnum.date,
            dataKey: 'date',
            selectableValues: DateRangeConfig,
            onAction: (newFilter: DateSelectionEventInterface) => {
                this.updateDateFilter(newFilter);
            }
        },
        {
            label: 'Typ',
            type: TableFilterTypeEnum.select,
            dataKey: 'type',
            selectableSignal: this.types,
            onAction: (newFilter: string, filterKey: string) => {
                this.updateSelectFilter(newFilter, filterKey);
            }
        },
        {
            label: 'Suche',
            type: TableFilterTypeEnum.search,
            dataKey: 'search'
        }
    ];

    /* ################ filters on the right side (above the sidebar) ################ */
    public filterRightConfig: TableFilterConfigInterface[] = [
        {
            icon: 'information-outline',
            type: TableFilterTypeEnum.info,
            ttk: 'mea_downloads_hint',
            onAction: async () => {
                const modal = await this.modalService.create(
                    MeaDownloadsInfoModalComponent,
                    {}
                );

                await modal.present();
            }
        }
    ];


    /* ################ search modal filters configuration ################ */
    public SearchModalFilterConfig: TableSearchModalConfigInterface = {
        title: 'Retouren filtern',
        onAction: (newFilters: MeaDownloadsFiltersInterface) => this.onSearchModalAction(newFilters),
        onReset: () => this.onSearchModalCancel(),
        items: [
            {
                label: 'Suche',
                type: TableFilterTypeEnum.search,
                dataKey: 'search',
            },
            {
                label: 'Datum',
                type: TableFilterTypeEnum.date,
                dataKey: 'date',
                selectableValues: DateRangeConfig,
                dateSignal: this.dateFilter,
                onAction: (newFilter: DateSelectionEventInterface) => {
                    this.updateDateFilter(newFilter);
                }
            },
            {
                label: 'Typ',
                type: TableFilterTypeEnum.select,
                dataKey: 'type',
                selectableSignal: this.types,
            },
        ]
    };

    private buildSidebarItems(download: MeaDownloadsDataInterface): TableSidebarItemsInterface[] {
        return [
            {
                key: 'updatedAt',
                value: formatDateTimeToDate(download.updatedAt, true),
                colWidth: '100%',
                label: 'Aktualisierungsdatum',
            },
            {
                key: 'downloadTypes',
                value: download.downloadTypes,
                badgeType: BadgeTypeEnum.MEA_DOWNLOADS_MULTIPLE,
                label: 'Typ',
                colWidth: '100%',
            },
            {
                key: 'format',
                value: download.file.split('.').pop().toUpperCase(),
                label: 'Dokumentformat',
                colWidth: '100%',
            },
            {
                key: 'description',
                value: download.description,
                label: 'Kurzbeschreibung',
                colWidth: '100%',
            },
            {
                key: 'file',
                value: download.file,
                isPreview: true,
                previewColor: download.downloadTypes?.[0]?.color,
                previewAction: () => this.onDownload(),
                label: 'Vorschau',
                colWidth: '100%',
            }
        ];
    }

    /**
     * Open new browser tab and print table as PDF.
     */
    private async onPrint() {
        const download = this.downloads().find(item => item.id === this.selectedId());
        this.pdfService.openPdfInNewTab(download.file);
    }

    /**
     * Convert table values to excel or csv and download the file.
     */
    private async onDownload() {
        const download = this.downloads().find(item => item.id === this.selectedId());
        await this.pdfService.downloadPdfByUrl(download.file, download.label);
    }

    public updateActiveId(upDown: number) {
        const selectedRowIndex = this.downloads().findIndex(item => item.id === this.selectedId());
        const newSelectedId = this.downloads()[selectedRowIndex + upDown]?.id || this._selectedId();
        this._selectedId.set(newSelectedId);
    }


    private onSearchModalAction(newFilters: MeaDownloadsFiltersInterface) {
        this._filters.update(prev => {
            return {
                ...prev,
                ...newFilters,
            };
        });
    }

    private onSearchModalCancel() {
        this._filters.set(defaultMeaDownloadsFilters);
    }


    /**
     * Get the mea download types
     */
    public getTypes(): Observable<MeaDownloadTypesInterface[]> {
        return this.meaQueries.getMeaDownloadTypes().pipe(map(d => {
            d.unshift({id: '0', type: MeaDownloadTypes.ALL, color: ''});
            return d;
        }));
    }

    /**
     * Set the id for a specific selected download item. For this item the sidebar is displayed.
     * @param id of the download item
     */
    public setSelectedId(id: string) {
        this._selectedId.set(id);
    }

    public setFilters(newFilters: MeaDownloadsFiltersInterface) {
        this._filters.update(prev => {
            return {
                ...prev,
                ...newFilters,
            };
        });
    }


    /**
     * Change the sorting of the table.
     * The sorting is saved to the storage by using an effect to automatically save the sorting as soon as it changes.
     * @param field - the field to sort by
     */
    public updateSort(field: TableFieldsEnum) {
        this._sort.update(prev => ({
            ...prev,
            field,
            order: this._sort().order === SortDirectionEnum.asc ? SortDirectionEnum.desc : SortDirectionEnum.asc,
        }));
    }


    private sortData() {
        const sort = this._sort();
        const meaDownloads = this.downloads();
        if (!sort || !meaDownloads) {
            return [];
        }

        return meaDownloads.sort((a, b) => {

            if(sort.field === TableFieldsEnum.file) {
                // Sort by file extension
                const aFile = a[sort.field].split('.').pop();
                const bFile = b[sort.field].split('.').pop();
                if (sort.order === SortDirectionEnum.asc) {
                    return aFile > bFile ? 1 : -1;
                } else {
                    return aFile < bFile ? 1 : -1;
                }
            } else {

                if (sort.order === SortDirectionEnum.asc) {
                    return a[sort.field] > b[sort.field] ? 1 : -1;
                } else {
                    return a[sort.field] < b[sort.field] ? 1 : -1;
                }
            }
        });
    }

    /**
     * Update the date filter
     * @param newFilter the new date filter
     * @param keyPrefix prefix of the attribute keys, e.g. 'date' or 'deliveryNoteDate'
     */
    public updateDateFilter(newFilter: DateSelectionEventInterface, keyPrefix = 'date') {
        this._filters.update(prev => {
            return {
                ...prev,
                [keyPrefix + 'Option']: newFilter?.dateOption,
                [keyPrefix + 'From']: newFilter?.dateFrom,
                [keyPrefix + 'To']: newFilter?.dateTo,
            };
        });
    }


    /**
     * Save filters that are selected using a popover.
     * The filters are saved to the storage by using an effect to automatically save the filters as soon as they change.
     * @param newFilter string value of the new filter
     * @param filterKey key of the filter that is being updated
     */
    public updateSelectFilter(newFilter: string, filterKey: string) {
        this._filters.update(prev => {
            return {
                ...prev,
                [filterKey]: newFilter,
            };
        });
    }
}
