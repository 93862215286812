import { Injectable, inject } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable, switchMap } from 'rxjs';
import { QueryWrapper } from '../query.wrapper';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { SurveyInterface, SurveyOverviewInterface } from '../../../interfaces/survey.interface';
import { formatStrapiData, getFiltersStrapi } from '../utils';
import { SurveyOtherAnswerId, SurveyQuestionTypeEnum } from '../../../enums/survey.enum';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { compareDates, formatDateToDefaultDateFormat, today } from '../../../formatting/date.formatting';
import { CompareDateEnum } from '../../../enums/date.enum';
import { PharmacyStoreInterface } from '../../../core.interfaces';
import { PharmacyStoreStateVar } from '../../locals/pharmacyStoreState.var';

export const GetSurveys = (queryName) => gql`
    query ${queryName}($filters: SurveyFiltersInput) {
        surveys(filters: $filters, pagination: {limit: 3}) {
            data {
                id
                attributes {
                    partner
                    title
                    toDate
                    logo {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GetSurveyById = gql`
    query GetSurveyById($filters: SurveyFiltersInput, $answersLimit: Int, $surveyLimit: Int) {
        surveys(filters: $filters, pagination: {limit: $surveyLimit}) {
            data {
                id
                attributes {
                    publishedAt
                    introduction
                    partner
                    title
                    fromDate
                    toDate
                    logo {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    question {
                        ... on ComponentSurveyMultipleChoice {
                            id
                            description
                            isAnswerRequired
                            isMultipleChoice
                            question
                            hasOthersAnswer
                            answers(pagination: {limit: $answersLimit}) {
                                id
                                answer
                            }
                        }
                        ... on ComponentSurveyFreeText {
                            id
                            question
                            description
                            isAnswerRequired
                        }
                    }
                }
            }
        }
    }
`;

export const GetSurveyUser = (queryName) => gql`
    query ${queryName}($limit: Int) {
        surveyUser(limit: $limit) {
            surveyId
        }
    }
`;

export const GetSurveyUserBySurveyId = gql`
    query getSurveyUserBySurveyId($limit: Int, $surveyId: Int!) {
        surveyUser(limit: $limit, where: {surveyId: {_eq: $surveyId}}) {
            surveyId
        }
    }
`;

type SurveyVariablesParams = {
    pharmacy: PharmacyStoreInterface;
    limit?: number;
    surveyId?: number;
    answersLimit?: number;
    fromDate?: string;
    toDate?: string;
};

export const getSurveyVariables = ({
    pharmacy,
    limit,
    surveyId,
    answersLimit,
    fromDate,
    toDate,
}: SurveyVariablesParams) => {
    const additionalFilters = getFiltersStrapi(pharmacy);
    const variables: any = {
      ...(limit && {limit}),
      ...(answersLimit && { answersLimit }),
      filters: {
        ...additionalFilters,
        ...(surveyId && { id: { eq: surveyId } }),
        ...(fromDate && { fromDate: { lte: fromDate } }),
        ...(toDate && { toDate: { gte: toDate } }),
      },
    };

    return variables;
};

export const AllSurveyQueries = [
    GetSurveys('test'),
    GetSurveyById,
    GetSurveyUser('test')
];

@Injectable()
export class SurveyQueries extends QueryWrapper {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    fetchPolicies = {
        [FPK.getSurveys]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getUserSurvey]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.surveyChanged]: [
                FPK.getSurveys
            ],
            [DCK.surveyUserChanged]: [
                FPK.getUserSurvey
            ]
        });
    }

    activePharmacyStore$ = this.pharmacyStoreStateVar.activePharmacyStoreState$;

    public getSurveys(): Observable<SurveyOverviewInterface[]> {
        const fetchPolicyKey = FPK.getSurveys;
        const todayDate = today();
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetSurveys(fetchPolicyKey),
                        variables: getSurveyVariables({
                            pharmacy,
                            fromDate: todayDate,
                            toDate: todayDate,
                            answersLimit: GraphQLLimits.surveyAnswers,
                        }),
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(
                        map((d) => d?.data && d.data['surveys'] && d.data['surveys']['data']),
                        map((d) => d?.map((item) => formatStrapiData(item))),
                        map((d) =>
                            d?.map((item) => ({
                                ...item,
                                logoUrl: item.logo?.data?.attributes?.url,
                            }))
                        ),
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        ) as Observable<SurveyOverviewInterface[]>;
    }

    public getSurveyById(id: number): Observable<SurveyInterface | null> {
        return this.activePharmacyStore$.pipe(
            switchMap(
                (pharmacy) =>
                    this.apollo
                        .watchQuery({
                            query: GetSurveyById,
                            variables: {
                                ...getSurveyVariables({
                                    pharmacy,
                                    surveyId: id,
                                    answersLimit: GraphQLLimits.surveyAnswers,
                                }),
                                surveyLimit: 1
                            },
                            fetchPolicy: QueryFetchPolicy.NETWORK_ONLY,
                        })
                        .valueChanges.pipe(
                            map((d) => d?.data && d.data['surveys'] && d.data['surveys']['data']),
                            map((d) => d?.length > 0 ? d[0] : null),
                            map((d) => formatStrapiData(d)),
                            map((d) =>
                                d.publishedAt &&
                                !!compareDates(today(), formatDateToDefaultDateFormat(d.fromDate), CompareDateEnum.isSameOrAfter) &&
                                !!compareDates(today(), formatDateToDefaultDateFormat(d.toDate), CompareDateEnum.isSameOrBefore)
                                    ? d
                                    : null
                            ),
                            map((d) => {
                                if (!d) {
                                    return d;
                                }
                                const data = {
                                    questions: d.question.map((question) => {
                                        if (question.__typename === SurveyQuestionTypeEnum.multipleChoice) {
                                            const answers = question.answers.map((answer) => {
                                                return { ...answer, isSelected: false };
                                            });
                                            if (question.hasOthersAnswer) {
                                                answers.push({
                                                    __typename: SurveyQuestionTypeEnum.multipleChoice,
                                                    id: SurveyOtherAnswerId.other,
                                                    answer: 'Sonstige',
                                                    freeTextAnswer: '',
                                                });
                                            }
                                            return { ...question, answers };
                                        } else {
                                            return { ...question, freeTextAnswer: '' };
                                        }
                                    }),
                                    logoUrl: d.logo?.data?.attributes?.url,
                                    ...d,
                                };
                                delete data.question;
                                return data;
                            })
                        ) as Observable<SurveyInterface | null>
            )
        );
    }

    public getSurveyUser(): Observable<Array<number>> {
        const fetchPolicyKey = FPK.getUserSurvey;
        return this.apollo
            .watchQuery({
                query: GetSurveyUser(fetchPolicyKey),
                variables: {
                    limit: GraphQLLimits.surveyAnswers
                },
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            })
            .valueChanges.pipe(
                map((data) => (data?.data && data.data['surveyUser'] && data.data['surveyUser']) || []),
                map((data) => data.map((item) => item?.surveyId)),
                map((d) => {
                    if (d) this.updateFetchPolicy(fetchPolicyKey);
                    return d;
                })
            ) as Observable<Array<number>>;
    }

    public getSurveyUserBySurveyId(surveyId: number): Observable<number | null> {
        return this.apollo
            .watchQuery({
                query: GetSurveyUserBySurveyId,
                variables: {
                    surveyId,
                    limit: GraphQLLimits.surveyAnswers,
                },
                fetchPolicy: QueryFetchPolicy.NETWORK_ONLY,
            })
            .valueChanges.pipe(
                map((data) => (data?.data && data.data['surveyUser'] && data.data['surveyUser']) || null),
                map((data) => (data?.length > 0 ? data[0]?.surveyId : null))
            ) as Observable<number | null>;
    }
}
