import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import { DocumentType } from '../../../core.enums';
import { TrackingStateVar } from '../../locals/trackingState.var';

export const DocumentSearch = gql`
    mutation DocumentSearch($documentType: DocumentSearchType!, $search: String, $fromDate: String, $toDate: String) {
        delete_documentFilterStatus(where: {}) {
            affected_rows
        }
        DocumentSearch(documentType: $documentType, search: $search, fromDate: $fromDate, toDate: $toDate) {
            status
            message
        }
    }
`;

export const AllDocumentMutations = [DocumentSearch];

@Injectable()
export class DocumentMutations {
    constructor(private apollo: Apollo, private trackingStateVar: TrackingStateVar) {}

    /**
     * Start a document filtering
     *
     * @param documentType - Type of document
     * @param search - Text to search
     * @param fromDate - From date
     * @param toDate - To date
     */
    public filterDocuments(documentType = DocumentType.LS, search: string, fromDate: string, toDate: string): Observable<boolean> {
        const startTime = performance.now();
        const variables = {
            documentType,
            search,
        };
        if (fromDate !== '' && toDate !== '') {
            variables['fromDate'] = fromDate;
            variables['toDate'] = toDate;

            // Check if more than 6 months difference
            const from = new Date(fromDate);
            const to = new Date(toDate);
            const diff = Math.abs(to.getTime() - from.getTime());
            const diffMonths = diff / (1000 * 60 * 60 * 24 * 30);
            if (diffMonths > 6) {
                throw new Error('Die Suche kann maximal 6 Monate umfassen.<br>Bitte ändern Sie das Filter für das Belegdatum.');
            }
        } else {
            throw new Error('Die Suche kann maximal 6 Monate umfassen.<br>Bitte ändern Sie das Filter für das Belegdatum.');
        }

        return new Observable<boolean>((subscriber) => {
            this.apollo
                .mutate({
                    mutation: DocumentSearch,
                    variables,
                })
                .subscribe((result) => {
                    from([result])
                        .pipe(
                            map((d) => d?.data && d?.data['DocumentSearch']),
                            map((documentSearch) => {
                                const wasSuccessful = documentSearch && documentSearch['status'] === 'SUCCESS';
                                this.trackingStateVar.set({
                                    deliverySearchStart: wasSuccessful ? startTime : -1,
                                    deliverySearchText: search,
                                });
                                subscriber.next(wasSuccessful);
                                return wasSuccessful;
                            })
                        )
                        .subscribe(); // executes the observable chain
                });
        });
    }
}
