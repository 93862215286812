import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from '@angular/common';
@Pipe({
    name: 'formatCurrency',
})
export class FormatCurrencyPipe implements PipeTransform {
    transform(
        value: number,
        currencyCode: string = 'EUR',
        digitsInfo: string = '1.2-2',
        locale: string = 'de'
    ): string | null {
        const currencySymbol = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyCode
        }).formatToParts().find(part => part.type === 'currency')?.value || currencyCode;

        return formatCurrency(
            value,
            locale,
            currencySymbol,
            currencyCode,
            digitsInfo,
        );
    }
}
