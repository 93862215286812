import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MeamindQueries } from '../../../../../../core/store/graphql/queries/meamind.graphql';
import { MeamindFilterAllSolved } from '../../../../../../core/config/meamind-filters.config';
import { MeamindItemInterface } from '../../../../../../core/interfaces/meamind.interface';
import { NavigationRoutesEnum } from '../../../../../../navigation/navigation.routes';
import { ModalService } from '../../../../../../core/services/modal.service';
import { unsubscribe, unsubscribeAll } from '../../../../../../core/core.utils';

@Component({
    selector: 'app-meamind-similar-questions',
    templateUrl: 'meamind-similar-questions.component.html',
    styleUrls: ['meamind-similar-questions.component.scss']
})
export class MeamindSimilarQuestionsComponent implements OnChanges {
    @Input() questionTitle: string;

    @HostBinding('class.hidden') get hidden() {
        return !this.showSimilarQuestions;
    }

    showSimilarQuestion = false;
    showSimilarQuestions = false;
    selectedSimilarQuestion = null;

    isLoading = false;
    questions: MeamindItemInterface[] = [];
    questionDetails: MeamindItemInterface = null;


    subscriptions: {
        similarQuestions: Subscription,
        similarQuestion: Subscription
    } = {
        similarQuestions: null,
        similarQuestion: null
    };

    constructor(
        private router: Router,
        private meamindQueries: MeamindQueries,
        private modalService: ModalService) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.questionTitle) {
            // Start searching for similar questions if provided title is longer than 4 letters
            if(this.questionTitle.length > 4) {
                this.showSimilarQuestions = true;
                this.loadSimilarQuestions();
            }
        }
    }

    /**
     * Clear every subscription
     */
    clear() {
        unsubscribeAll([
            this.subscriptions.similarQuestions,
            this.subscriptions.similarQuestion
        ]);
    }

    /**
     * Load similar questions to the provided query
     */
    loadSimilarQuestions() {
        this.isLoading = true;
        unsubscribe(this.subscriptions.similarQuestions);
        this.subscriptions.similarQuestions = this.meamindQueries
            .getQuestions( 5, 0, MeamindFilterAllSolved, this.questionTitle)
            .subscribe(questions => {
                this.isLoading = false;
                this.questions = [];
                if(questions && questions && questions.length > 0) {
                    // filter null values in questionResult.item array e.g. search for "Test IT 29.09.2019"
                    this.questions = questions.filter(result => result !== null && result.title);
                }
        });
    }

    /**
     * Load a single question
     */
    loadSimilarQuestion() {
        this.questionDetails = null;

        unsubscribe(this.subscriptions.similarQuestion);
        this.subscriptions.similarQuestion = this.meamindQueries
            .getQuestionById( this.selectedSimilarQuestion.id)
            .subscribe(question => {
                this.questionDetails = question;
        });
    }

    /**
     * User selected a question
     *
     * @param similarQuestion - selected question
     */
    onQuestionItemClick(similarQuestion) {
        this.selectedSimilarQuestion = similarQuestion;
        this.showSimilarQuestion = true;
        this.loadSimilarQuestion();
    }

    /**
     * User pressed the back button
     */
    onBackClick() {
        this.showSimilarQuestion = false;
        this.selectedSimilarQuestion = null;
    }

    /**
     * Navigate to meamind and open up the selected question
     */
    async onOpenInMeamind() {
        await this.router.navigate([NavigationRoutesEnum.meamind, { id: this.selectedSimilarQuestion.id}]);
        return this.modalService.dismiss();
    }

}
