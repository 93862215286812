import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PdfViewerPayloadInterface } from '../../interfaces/pdf.interface';
import { PdfViewerType } from '../../enums/pdf.enum';
import { matomoIdsEnum } from '../../enums/matomo-ids.enum';
import { PdfViewerService } from './pdf-viewer.service';
import { PdfProviderService } from '../../services/pdfProvider.service';
import { ButtonTypes } from '../../enums/button-actions.enum';

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerComponent implements OnInit {
    @Input() type : PdfViewerType = PdfViewerType.delivery;
    @Input() payload: PdfViewerPayloadInterface;
    @Input() isPdfLoading?: boolean;

    /**
     * Show a different loading animation to indicate a longer loading time
     */
    @Input() isLongLoading = false;

    /**
     * Show title next to the loader
     */
    @Input() extendedLoader = false;

    @Input() tooltipKey?: string;
    @Input() downloadDisabled = false;
    @Input() printDisabled = false;
    @Input() useGeneralTooltips = false;
    @Output() actionClicked: EventEmitter<ButtonTypes> = new EventEmitter<ButtonTypes>();



    @HostBinding('class') get hostClasses() {
        if((this.extendedLoader || this.isLongLoading) && this.isLoading$.value) {
            return 'long-loading';
        }
    }


    matomoClass = matomoIdsEnum.logisticsActionDelivery;

    pdf: { payload: PdfViewerPayloadInterface, type: PdfViewerType, file: { result: number[], error: string } };
    isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(
        private viewerService: PdfViewerService,
        private printPdfService: PdfProviderService
    ) {
    }

    ngOnInit() {
        if (this.isPdfLoading) {
            this.isLoading$.next(this.isPdfLoading);
        }

        switch(this.type){
            case PdfViewerType.delivery:
                this.matomoClass = matomoIdsEnum.logisticsActionDelivery;
                break;
            case PdfViewerType.batch:
                this.matomoClass = matomoIdsEnum.logisticsActionBatch;
                break;
            case PdfViewerType.invoice:
                this.matomoClass = matomoIdsEnum.logisticsActionInvoice;
                break;
            case PdfViewerType.meaDownload:
                this.matomoClass = matomoIdsEnum.meaDownload;
                break;
            case PdfViewerType.statistics:
                this.matomoClass = matomoIdsEnum.statisticsDownload;
                break;
            case PdfViewerType.terms:
                this.matomoClass = matomoIdsEnum.termsAndConditions;
                break;
        }
    }

    onDownloadClicked() {
        this.actionClicked.emit(ButtonTypes.DOWNLOAD);
        if (this.isLoading$.value) {
            return;
        }
        this.isLoading$.next(true);
        if (this.payload?.url) {
            this.printPdfService.downloadPdfByUrl(this.payload.url, this.payload.filename).then(() => {
                this.isLoading$.next(false);
            });
        } else {
            this.getPdf().then(pdf => {
                if (pdf && pdf.file.result && pdf.file.result.length > 0) {
                    this.viewerService.download(pdf.file.result, this.payload.filename);
                }
                this.isLoading$.next(false);
            });
        }
    }

    onPrintClicked() {
        this.actionClicked.emit(ButtonTypes.PRINT);
        if (this.isLoading$.value) {
            return;
        }
        if (this.payload?.url) {
            this.printPdfService.openPdfInNewTab(this.payload.url);
        } else {
            this.isLoading$.next(true);
            this.isLoading$.next(this.printPdfService.getDocumentPdf(this.type, this.payload));
        }
    }

    async getPdf() {
        if (!this.pdf ||
            !this.pdf.file.result ||
            this.pdf.file.result.length === 0 ||
            this.pdf.type !== this.type ||
            this.pdf.payload !== this.payload
        ) {
            this.pdf = {
                payload: this.payload,
                type: this.type,
                file:await this.viewerService.getPdfAsByteArray(this.type, this.payload)
            };
        }
        return this.pdf;
    }
}

