<app-expandable-sidebar
        [title]="'Ähnliche Fragen'"
        [isSidebarVisible]="showSimilarQuestions && !showSimilarQuestion"
        [hasBorderRadius]="true"
        [borderRadiusType]="'all'"
        [isDetailsVisible]="showSimilarQuestion">

    <!-- Loading -->
    <ng-container content *ngIf="isLoading">
        <div *ngFor="let item of [1,2]" class="similar-question-item skeleton">
            <ion-text>
                <ion-skeleton-text animated></ion-skeleton-text>
            </ion-text>
            <div class="display-flex">
                <ion-skeleton-text animated></ion-skeleton-text>
                <ion-skeleton-text animated></ion-skeleton-text>
            </div>
        </div>
    </ng-container>

    <ng-container content *ngIf="!isLoading">
        <!-- No questions found -->
        <div class="display-flex full-height ion-justify-content-center question-no-items" *ngIf="questions && questions.length === 0 && questionTitle && questionTitle.length > 4">
            <ion-text class="h4" color="medium" i18n>Es konnten keine ähnlichen Fragen gefunden werden.</ion-text>
        </div>

        <!-- List of questions -->
        <div *ngFor="let question of questions" class="similar-question-item" (click)="onQuestionItemClick(question)">
            <div class="creation-time">{{question.created_at | dateTimeToDate : true}}</div>
            <ion-text class="h2 bold">{{question.title}}</ion-text>
            <div class="display-flex flex-wrap">
                <app-meamind-tag *ngFor="let tag of question.tags" [tag]="tag"></app-meamind-tag>
            </div>
            <ion-icon name="arrow-forward-outline"></ion-icon>
        </div>
    </ng-container>

    <ng-container details>
        <div *ngIf="selectedSimilarQuestion" class="similar-question-content">
            <ion-text class="h1">{{selectedSimilarQuestion.title}}</ion-text>
            <ion-icon name="arrow-back-outline" (click)="onBackClick()"></ion-icon>

            <!-- SOLUTIONS -->
            <div class="similar-question-solutions" *ngIf="questionDetails">
                <div *ngFor="let solution of questionDetails.meamindAnswers">
                    <ion-text i18n>Von {{solution.created_by ? solution.created_by.trim() : 'Unbekannt'}} | {{solution.created_at | formatDateInComparisonToTime : true}}</ion-text>
                    <ion-text [innerHTML]="solution.content | linksTarget"></ion-text>
                </div>
            </div>
            <div class="similar-question-solutions" *ngIf="!questionDetails">
                <div *ngFor="let solution of[1,2]">
                    <ion-skeleton-text animated class="skeleton-small"></ion-skeleton-text>
                    <ion-skeleton-text animated></ion-skeleton-text>
                    <ion-skeleton-text animated></ion-skeleton-text>
                    <ion-skeleton-text animated></ion-skeleton-text>
                </div>
            </div>
        </div>
    </ng-container>

</app-expandable-sidebar>
