import { Pipe, PipeTransform } from '@angular/core';
import { CommunicationZoneInterface } from '../interfaces/communication-zone.interface';
import { CommunicationZoneType } from '../enums/communication-zone.enum';

@Pipe({
    name: 'communicationZoneHasReference'
})
export class CommunicationZoneHasReferencePipe implements PipeTransform {

    transform(communicationZoneData: CommunicationZoneInterface): boolean {
        const DOCUMENTS_WITH_REFERENCE = [
          CommunicationZoneType.DOCUMENT_ESR,
          CommunicationZoneType.DOCUMENT_ETR,
          CommunicationZoneType.DOCUMENT_CH,
          CommunicationZoneType.DOCUMENT_LS
        ];

        const hasReference =
          communicationZoneData.orderId !== null ||
          (communicationZoneData.entryType === CommunicationZoneType.RETURN && communicationZoneData.payload['referenceId']) ||
          (DOCUMENTS_WITH_REFERENCE.includes(communicationZoneData.entryType) && communicationZoneData.payload['referenceNumber']) ||
          communicationZoneData.entryType === CommunicationZoneType.DOCUMENT_SB;

        return hasReference;
    }
}
