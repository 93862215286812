import {
    Component,
    Input,
    Output,
    SimpleChanges,
    EventEmitter
} from '@angular/core';

import * as moment from 'moment';

import * as d3 from 'd3';
import * as d3S from 'd3-selection';
import * as d3Scale from 'd3-scale';
import 'd3-transition';
import { formatCurrency } from '@angular/common';
import { MonthlyStatisticsInterface } from '../../interfaces/statistics.interface';

import { StatisticsSizeEnum } from '../../enums/statistics.enum';

@Component({
    selector: 'app-bar-chart-base',
    template: '',
    styleUrls: ['./bar-chart-base.component.scss'],
})
export class BarChartBaseComponent {

    constructor() {
        this.generateUniqueId();
    }
    @Input() data: {
        previous:Array<MonthlyStatisticsInterface>,
        current:Array<MonthlyStatisticsInterface>
    };
    @Input() tooltipData: Array<{label: string, data: Array<MonthlyStatisticsInterface>}>;
    @Input() isLoading = false;
    @Input() isBarClickable = false;
    @Input() showLegend = false;
    @Input() showDetails = false;
    @Input() showYLine = false;
    @Input() size = StatisticsSizeEnum.DEFAULT;
    @Input() unit = '';
    @Input() v2 = false;
    @Input() rerender = new EventEmitter();
    @Output() barClicked = new EventEmitter();

    uniqueID = '';

    // Chart Variables
    width: number;
    height: number;
    svg: any;
    chart: any;
    lines: any;
    crosshair: any;
    tooltip: any;
    monthLabels: any;
    xScale: any;
    yScale: any;
    xDomain: any;
    yDomain: any;
    offset: number;
    yMax = 0;
    yMin = 0;
    isChartInitialized = false;
    tries = 0;

    months = [];

    currentMonth = moment().format('MM');

    highlightLastYear = false;
    highlightCurrentMonth = false;

    onResize() {
       this.rerenderChart();
    }

    onChanges(changes: SimpleChanges): void {
        if(changes.isLoading && this.data
        || changes.data && !this.isLoading) {
            if(!this.isChartInitialized) {
                this.initChart();
            } else {
                this.initSVG();
                this.drawChart();
            }

        }
    }

    /**
     * Called if ion view did enter
     */
    ionViewDidEnter() {
        if(!this.isChartInitialized) {
            this.initChart();
        }
    }


    rerenderChart() {}

    /**
     * Implement respective height and width calculation in this helper function in child components.
     */
    calculateWidthHeight() {}

    /**
     * Generate a unique id to prevent duplicate id's in html
     */
    generateUniqueId() {
        this.uniqueID = 'barChart_' + Math.round(Math.random() * 10000000);
    }

    /**
     * Check if it is possible to create the chart, initialize it if it is possible
     */
    initChart() {}

    /**
     * Initialize the chart svg
     */
    initSVG() {
        if (!this.data || !Array.isArray(this.data.current)) {
            return;
        }
        d3S.select('#' + this.uniqueID + ' svg').remove();
        d3S.select('#' + this.uniqueID + ' .tooltip').remove();
        this.svg = d3S.select('#'+this.uniqueID)
            .append('svg')
            .attr('height', '100%')
            .attr('width', '100%')
            .attr('cursor', 'default')
            .attr('preserveAspectRatio', 'xMinYMax meet')
            .attr('viewBox', `0 0 ${this.width} ${this.height}`);

        this.chart = this.svg.append('g')
            .attr('class', 'bars')
            .attr('transform', 'translate(0,0)');
        this.lines = this.svg.append('g')
            .attr('class', 'lines')
            .attr('transform', 'translate(0,0)');
        this.monthLabels = this.svg.append('g')
            .attr('class', 'months')
            .attr('transform', 'translate(0,0)');

        if(this.showYLine) {
            this.crosshair = this.svg.append('g')
                .attr('class', 'crosshair');
        }

        this.svg.on('mouseover', () => this.handleRootMouseOver())
            .on('mouseout', (event) => this.handleRootMouseOut(event))
            .on('mousemove', (event) => this.handleRootMouseMove(event));

        this.calculateYMaxMin();

        this.xDomain = this.data.current.map(item => item.month);
        this.yDomain = [this.yMin, this.yMax];
        const barWidth = Math.floor(this.width / this.data.current.length) - 1;

        this.xScale = d3Scale.scaleBand()
            .padding(0.05)
            .domain(this.xDomain)
            .range([0, this.width + (barWidth / this.data.current.length)]);
        this.yScale = d3Scale.scaleLinear()
            .domain(this.yDomain)
            .range([this.v2 ? this.height - this.offset : this.height - this.offset, 0]);
    }

    calculateYMaxMin() {}

    /**
     * Draw the chart depending on the values
     */
    drawChart() {
        if (!this.xScale || !this.data || !Array.isArray(this.data.current)) {
            return;
        }
        this.xScale.domain(this.data.current.map(item => item.month));
        this.yScale.domain([0, this.yMax]);
    }

    drawCrosshair() {
        const crosshair = this.crosshair.selectAll();
        crosshair
            .remove()
            .exit();

        this.crosshair.append('line')
            .attr('class', 'crosshairY');
        this.crosshair.append('rect')
            .attr('class', 'crosshairYLabelBG');
        this.crosshair.append('text')
            .attr('class', 'crosshairYLabel');
    }

    /**
     * User hovers a bar
     *
     * @param event - The mouse event
     * @param item - The hovered item
     */
    handleMouseOver(event, item) {
        this.tooltip = d3S.select('#' + this.uniqueID)
            .append('div')
            .attr('class', 'tooltip tooltip-right')
            .style('opacity', 0);

        const barWidth = Math.floor(this.width / this.data.current.length) - 1;

        let text = '';
        this.tooltipData.forEach(data => {
            const dataItem = data.data.find(d => d.month === item.month);
            text = text
                + (text.length > 0 ? '<br/>' : '')
                + data.label + ' '
                + (dataItem.value !== 0 ? Intl.NumberFormat('de-DE').format(dataItem.value) + ' ' + this.unit : 'k. A.');
        });

        this.tooltip.transition()
            .duration(200)
            .style('opacity', 1)
            .style('display', 'block');
        let positionClass = 'tooltip tooltip-right';
        let position = 'left';
        let xPos = this.xScale(item.month) + barWidth + 5;
        let clearPosition = 'right';
        if (item.month > 6) {
            positionClass = 'tooltip tooltip-left';
            position = 'right';
            xPos = this.xScale('12') - this.xScale(item.month) + barWidth + 4;
            clearPosition = 'left';
        }
        this.tooltip
            .attr('class', positionClass)
            .html(text)
            .style('text-align', 'start')
            .style(position, xPos + 'px')
            .style(clearPosition, null)
            .style('top', this.yScale(item.value) - 5 + 'px');
    }

    /**
     * User stops to hover the bar
     *
     * @param event - The mouse event
     * @param item - The hovered item
     */
    handleMouseOut(event = null, item = null) {
        this.tooltip.transition()
            .duration(200)
            .style('opacity', 0);

        this.tooltip.remove();
    }

    /**
     * User hovers the chart
     *
     */
    handleRootMouseOver() {
        if(this.crosshair) {
            this.crosshair.style('display', null);
        }
    }

    /**
     * User stops to hover the chart
     *
     * @param event - The mouse event
     */
    handleRootMouseOut(event) {
        if (this.crosshair) {
            this.crosshair.style('display', 'none');
        }
    }

    handleRootMouseMove(event) {
        const barWidth = Math.floor(this.width / this.data.current.length) - 1;
        // check if getScreenCTM is null - d3.pointer(...) throws an exception if it is null
        if (!(event?.currentTarget?.getScreenCTM())) {
            return;
        }
        const mouse = d3.pointer(event);
        const x = mouse[0];
        const y = mouse[1];
        let xLabel = x - 4;
        const yLabel = y - 4;
        xLabel = yLabel > 20 ? x : xLabel + 30;
        const value =parseInt(this.yScale.invert(y).toString(),10);
        const chartWidth =  this.xScale(this.xDomain[this.xDomain.length - 1]) + barWidth;

        if(value > 0 && this.crosshair) {
            this.crosshair.select('.crosshairY')
                .attr('x1', this.xScale(this.xDomain[0]))
                .attr('y1', y)
                .attr('x2', chartWidth)
                .attr('y2', y);
            const currencySymbol = new Intl.NumberFormat('de', {
                style: 'currency',
                currency: 'EUR'
            }).formatToParts().find(part => part.type === 'currency')?.value || 'EUR';
            const text = this.crosshair.select('.crosshairYLabel');
                text.attr('x', xLabel > 10 ? xLabel : 10)
                    .attr('y', yLabel > 20 ? yLabel : yLabel + 20)
                    .text(this.unit === '€' ? formatCurrency(
                        value,
                        'de',
                        currencySymbol,
                        'EUR',
                        '1.2-2',
                    ): parseInt(value.toString(), 10) + ' ' + this.unit);

            const bbox = text.node().getBBox();
            const textRect = this.crosshair.select('.crosshairYLabelBG')
                .attr('x', bbox.x - 8)
                .attr('y', bbox.y - 2)
                .attr('width', bbox.width + 16)
                .attr('height', bbox.height + 4);

            if(bbox.width + bbox.x > chartWidth - (bbox.width / 2 )) {
                text.attr('x',  chartWidth - bbox.width - 8);
                textRect.attr('x',  chartWidth - bbox.width - 16);
            }
        }
    }

    handleClick(item) {
        this.barClicked.emit(item);
    }

    /**
     * Returns the class depending on the delivery
     *
     * @param date - Date of the item
     * @param classes - Additional classes for styling
     */
    getBarClass(date, classes = '') {
        const isCurrentMonth = this.convertToMonth(date) === this.currentMonth;
        return (isCurrentMonth ? 'bar current' : 'bar') + ' ' + classes;
    }

    /**
     * Returns the class depending on the delivery
     *
     * @param date - Date of the item
     * @param classes - Additional classes for styling
     */
    getTextClass(date, classes = '') {
        const isCurrentMonth = this.convertToMonth(date) === this.currentMonth;
        return (isCurrentMonth ? 'current' : '') + ' ' + classes;
    }

    /**
     * Format any date to day and Month
     *
     * @param date - Date to format
     */
    formatMonthAndYear(date) {
        return moment(date, 'MM').format('MMM YYYY').toString();
    }

    /**
     * track by
     *
     * @param index - The index of the item
     * @param item - The item to track
     */
    trackBy (index, item) {
        return item.label;
    }

    /**
     * convert date or month to month
     *
     * @param dateOrMonth - Date or month to convert
     */
    convertToMonth(dateOrMonth) {
        if (['01', '02', '03','04','05','06','07','08','09','10','11','12'].includes(dateOrMonth)) {
            return dateOrMonth;
        }
        return dateOrMonth ? moment(dateOrMonth).format('MM') : moment().format('MM');
    }
}
