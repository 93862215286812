<app-modal  i18n-title title="{{!returnGoodsSelectValue ? 'Retoure anmelden' : returnGoodsSelectValue + ' Retoure anmelden'}}" [contentPadding]="false" [contentFullHeight]="true">
    <ng-container headerActions></ng-container>
    <ng-container content>
        <div class="container">
            <div class="header form-container">
                <form class="form" [formGroup]="formGroup">
                    <app-dropdown-input
                            label="Ansprechpartner" i18n-label
                            appTooltip ttp="bottom" ttk="return_request_contact"
                            formControlName="contactName"
                            placeholder="{{tooltips.communication_zone_contact_person}}"
                            isRequired="true"
                            labelClass="label-min-width"
                            inputClass=""
                            [validationFieldEnum]="validationFieldsEnum.contactPerson"
                            [validators]="validators"
                            [items]="contactNames"
                            [isInvalid]="formGroup.controls.contactName?.dirty | formInputIsInvalid : formGroup.controls.contactName?.valid"
                            [tooltipInvalidValue]="formGroup.controls.contactName | formInputErrorMessage : formGroup.controls.contactName.value"
                    ></app-dropdown-input>
                    <div class="form-field-container zero-z-index">
                        <ion-item class="background-input" appTooltip ttp="bottom" ttk="dispo_request_additional_information">
                            <ion-textarea 
                                i18n-label
                                type="text"
                                formControlName="remarks"
                                aria-label="Anmerkungen"
                                label="Anmerkungen"
                                class="no-background large"
                                [ngClass]="{
                                    'invalid': !formGroup.controls.remarks?.pristine | formInputIsInvalid : formGroup.controls.remarks?.valid,
                                }"
                                [maxlength]="fieldLengths.remarks"
                                [placeholder]="'Weitere Informationen zur Retoure.'"
                            ></ion-textarea>
                            <ion-icon name="alert-circle"
                                      color="primary"
                                      *ngIf="!formGroup.controls.remarks?.pristine | formInputIsInvalid : formGroup.controls.remarks?.valid"
                                      appTooltip
                                      [tt]="formGroup.controls.remarks | formInputErrorMessage : formGroup.controls.remarks.value"
                                      ttp="right">
                            </ion-icon>
                        </ion-item>
                        <div class="hint-text-wrapper">
                            <ion-text class="hint" i18n>Hinweis: Die Anmerkung gilt für alle Retouren in dieser Meldung.</ion-text>
                            <div class="field-limit" i18n>Noch {{fieldLengths.remarks - formGroup.value['remarks'].length}} Zeichen</div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="sidebar is-modal">
                <div class="sidebar-headline" i18n>Retouren <span>({{slides.length}} {{slides.length > 1 ? ' Vorgänge' : 'Vorgang'}})</span></div>
                <div *ngFor="let slide of slides; index as index" class="return-item">
                    <div class="return-item-body clickable" [class.active]="activeSlide === index" (click)="slides.length > 1 && activeSlide !== index ? editSlide(index) : null">
                        <ion-text i18n>PZN: {{index === activeSlide ? formGroup.value['pzn'] : slide.pzn}}</ion-text>
                        <ion-text i18n>Typ: {{index === activeSlide ? formGroup.value.type : slide.type}}</ion-text>
                    </div>
                    <div class="return-item-icons" [class.active]="activeSlide === index">
                        <ion-icon name="close" class="return-item-close clickable" [class.isFirst]="index === 0" (click)="slides.length > 1 ? onRemoveSlideClick(index) : null"></ion-icon>
                        <ion-icon name="pencil-outline" class="return-item-edit clickable" (click)="slides.length > 1 ? editSlide(index) : null"></ion-icon>
                    </div>
                </div>
            </div>
            <swiper-container #returnGoodsSlider
                class="zero-z-index swiper"
                [modules]="swiperModules"
                [simulateTouch]="false"
                [shortSwipes]="false"
                [longSwipes]="false"
                [allowTouchMove]="false"
                [autoHeight]="true"
                [observer]="true"
            >
                <swiper-slide *ngFor="let slide of slides; index as index" class="ion-justify-content-center ion-padding-end">
                    <div class="form-container">
                        <ion-text *ngIf="returnGoodsSelectValue === returnGoodTypesEnum.coolingGoods" class="important-notice unset-max-width ion-text-left ion-margin-bottom" i18n>
                            <p>Saison-Impfstoffe (FSME/Encepur/Grippe) sind von der Rücknahme grundsätzlich ausgeschlossen.</p>
                        </ion-text>
                        <form [formGroup]="formGroup" (ngSubmit)="sendReturnRequest(formGroup.value)" class="form ion-padding-bottom">
                            <ion-item class="background-input">
                                <ion-label class="label" slot="start" i18n>Typ</ion-label>
                                <div class="ion-input-container large select-container">
                                    <ion-select formControlName="type" aria-label="Typ" (ionChange)="onReturnsTypeSelectionChange($event)">
                                        <ion-select-option [value]="returnGoodTypesEnum.btm">{{returnGoodTypesEnum.btm}}</ion-select-option>
                                        <ion-select-option [value]="returnGoodTypesEnum.coolingGoods">{{returnGoodTypesEnum.coolingGoods}}</ion-select-option>
                                        <ion-select-option [value]="returnGoodTypesEnum.dangerGoods">{{returnGoodTypesEnum.dangerGoods}}</ion-select-option>
                                        <ion-select-option [value]="returnGoodTypesEnum.highPrised">{{returnGoodTypesEnum.highPrised}}</ion-select-option>
                                    </ion-select>
                                </div>
                            </ion-item>
                            <ion-item class="background-input">
                                <ion-input
                                    i18n-label
                                    type="text"
                                    formControlName="quantity"
                                    label="Menge"
                                    class="required"
                                    [ngClass]="{
                                        'invalid': formGroup.controls.quantity?.dirty | formInputIsInvalid : formGroup.controls.quantity?.valid,
                                    }"
                                    [placeholder]="tooltips.return_request_qty"
                                    appTooltip ttp="bottom"
                                    ttk="return_request_qty"
                                ></ion-input>
                                <ion-icon
                                    name="alert-circle"
                                    color="primary"
                                    *ngIf="formGroup.controls.quantity?.dirty | formInputIsInvalid : formGroup.controls.quantity?.valid"
                                    appTooltip
                                    [tt]="formGroup.controls.quantity | formInputErrorMessage : formGroup.controls.quantity.value : 'Die Menge muss eine Ganzzahl sein.'"
                                    ttp="right">
                                </ion-icon>
                            </ion-item>
                            <ion-item class="background-input">
                                    <ion-input
                                        i18n-label
                                        type="text"
                                        formControlName="pzn"
                                        label="PZN"
                                        class="required"
                                        [ngClass]="{
                                            'invalid': !formGroup.controls.pzn?.pristine | formInputIsInvalid : formGroup.controls.pzn?.valid,
                                        }"
                                    ></ion-input>
                                    <ion-icon 
                                        name="alert-circle"
                                        color="primary"
                                        *ngIf="!formGroup.controls.pzn?.pristine | formInputIsInvalid : formGroup.controls.pzn?.valid"
                                        appTooltip
                                        [tt]="formGroup.controls.pzn | formInputErrorMessage : formGroup.controls.pzn.value"
                                        ttp="right">
                                    </ion-icon>
                            </ion-item>
                            <ion-item class="background-input">
                                <ion-input
                                    i18n-label
                                    type="text"
                                    formControlName="expirationDate"
                                    label="Verfalldatum"
                                    class="required"
                                    [ngClass]="{
                                        'invalid': !formGroup.controls.expirationDate?.pristine | formInputIsInvalid : formGroup.controls.expirationDate?.valid,
                                    }"
                                    [placeholder]="tooltips.return_request_expirationDate"
                                    appTooltip
                                    ttp="bottom"
                                    ttk="return_request_expirationDate"
                                ></ion-input>
                                <ion-icon
                                    name="alert-circle"
                                    color="primary"
                                    *ngIf="!formGroup.controls.expirationDate?.pristine | formInputIsInvalid : formGroup.controls.expirationDate?.valid"
                                    appTooltip
                                    [tt]="formGroup.controls.expirationDate | formInputErrorMessage : formGroup.controls.expirationDate.value"
                                    ttp="right">
                                </ion-icon>
                            </ion-item>
                            <ion-item class="background-input item-input custom-padding" i18n>
                                <ion-checkbox
                                    mode="md"
                                    formControlName="coolingChain"
                                    [class.invalid]="formGroup.controls.coolingChain?.dirty | formInputIsInvalid : formGroup.controls.coolingChain?.valid"
                                    justify="space-between"
                                >
                                    <div class="label">Kühlkette?</div>
                                </ion-checkbox>
                                <ion-text
                                        *ngIf="formGroup.controls.coolingChain.value !== false"
                                        class="important-notice center-text ion-margin-start ion-text-left ion-padding">
                                    Legen Sie das Kühlprotokoll der Retourenwanne bei.
                                </ion-text>
                            </ion-item>
                            <ion-item class="background-input item-input custom-padding" i18n>
                                <ion-checkbox
                                    mode="md"
                                    formControlName="securPharm"
                                    [class.invalid]="formGroup.controls.securPharm?.dirty | formInputIsInvalid : formGroup.controls.securPharm?.valid"
                                >
                                    <div class="label">Securpharmartikel?</div>
                                </ion-checkbox>
                                <ion-text
                                    *ngIf="formGroup.controls.securPharm.value !== false"
                                    class="important-notice center-text ion-margin-start ion-text-left"
                                >
                                    Bitte prüfen Sie vor der Retoure ob der Artikel aktiv ist.<br/> Deaktivierte Securpharm-Artikel dürfen wir nicht an Apotheken zurückschicken und müssen vernichtet werden.
                                </ion-text>
                            </ion-item>
                            <ion-item class="background-input">
                                <ion-input
                                    i18n-label
                                    type="text"
                                    formControlName="reason"
                                    label="Retourengrund"
                                    class="required"
                                    [ngClass]="{
                                        'invalid': !formGroup.controls.reason?.pristine | formInputIsInvalid : formGroup.controls.reason?.valid,
                                    }"
                                    [placeholder]="tooltips.return_request_reason"
                                    appTooltip
                                    ttp="bottom"
                                    ttk="return_request_reason"
                                ></ion-input>
                                <ion-icon
                                    name="alert-circle"
                                    color="primary"
                                    *ngIf="!formGroup.controls.reason?.pristine | formInputIsInvalid : formGroup.controls.reason?.valid"
                                    appTooltip
                                    [tt]="formGroup.controls.reason | formInputErrorMessage : formGroup.controls.reason.value"
                                    ttp="right">
                                </ion-icon>
                            </ion-item>
                            <ion-item class="background-input">
                                <div class="delivery-number-container app-outside-click-directive ion-align-items-stretch" (appClickOutside)="onClickOutsideOfDeliveryNrInput(index)">
                                    <ion-input
                                        i18n-label
                                        placeholder="z.B. 11-123456"
                                        formControlName="deliveryNr"
                                        label="Lieferscheinnummer"
                                        class="required"
                                        [ngClass]="{
                                            'invalid': !formGroup.controls.deliveryNr?.pristine | formInputIsInvalid : formGroup.controls.deliveryNr?.valid,
                                        }"
                                        (ionChange)="onDeliveryNrInput($event)"
                                        (ionFocus)="onDeliveryNrFocusChange(true)"
                                        (click)="onDeliveryNrFocusChange(true)"
                                        appTooltip ttp="bottom" ttk="return_request_deliveryNr">
                                    </ion-input>
                                    <ion-icon
                                        name="alert-circle"
                                        color="primary"
                                        *ngIf="!formGroup.controls.deliveryNr?.pristine | formInputIsInvalid : formGroup.controls.deliveryNr?.valid"
                                        appTooltip
                                        [tt]="formGroup.controls.deliveryNr | formInputErrorMessage : formGroup.controls.deliveryNr.value"
                                        ttp="right">
                                    </ion-icon>
                                    <ion-list [class.show-list]="showDeliveryNumberList">
                                        <ion-item button="true" *ngFor="let delNum of deliveryNrCollection | orderBy:'':true;" (click)="onDeliveryNumButtonClick(delNum)" detail="false" lines="none">
                                            <span class="label">{{(delNum && delNum['documentNumber'] || '') | documentNumberFormatted}}</span>
                                        </ion-item>
                                    </ion-list>
                                </div>
                            </ion-item>
                            <ion-item class="background-input">
                                <ion-input
                                    i18n-label
                                    class="date-input"
                                    type="date"
                                    inputmode="text"
                                    label="Lieferscheindatum"
                                    class="required has-icon"
                                    [ngClass]="{
                                        'invalid': !formGroup.controls.deliveryDate?.pristine | formInputIsInvalid : formGroup.controls.deliveryDate?.valid,
                                    }"
                                    min="{{returnGoodsSelectValue === returnGoodTypesEnum.btm || returnGoodsSelectValue === returnGoodTypesEnum.coolingGoods ? dateSixWeeksAgo : null }}"
                                    max="{{today}}"
                                    formControlName="deliveryDate"
                                    [placeholder]="tooltips.return_request_deliveryDate"
                                    appTooltip ttp="bottom" ttk="return_request_deliveryDate"
                                ></ion-input>
                                <ion-icon
                                    name="alert-circle"
                                    color="primary"
                                    *ngIf="!formGroup.controls.deliveryDate?.pristine | formInputIsInvalid : formGroup.controls.deliveryDate?.valid"
                                    appTooltip
                                    [tt]="formGroup.controls.deliveryDate | formInputErrorMessage : formGroup.controls.deliveryDate.value : 'Bitte geben Sie ein gültiges Datum ein.'"
                                    ttp="right">
                                </ion-icon>
                            </ion-item>
                        </form>
                        <div class="required-fields ion-text-left"><span class="required">*</span> Benötigte Felder</div>
                    </div>
                </swiper-slide>
            </swiper-container>
        </div>
    </ng-container>
    <!-- Footer -->
    <ng-container footer>
        <ion-button color="medium" class="margin-right-small" *ngIf="activeSlide > 0" (click)="onBackClick()" i18n>Zurück</ion-button>
        <ion-button
                color="medium"
                class="margin-right-small"
                (click)="formGroup.valid ? onAppendSlideClick() : null"
                [class.disabled]="this.slides.length >= maxSlides || !formGroup.valid"
                appTooltip
                ttk="{{slides.length >= maxSlides ? 'return_request_max' : (!formGroup.valid ? 'invalid_form' : null)}}"
                ttp="top"
                i18n
        >Weitere Retoure hinzufügen</ion-button>
        <ion-button
                color="primary"
                class="{{matomoClass}}"
                [class.disabled]="!formGroup.valid"
                (click)="formGroup.valid ? sendReturnRequest(formGroup.value) : null"
                appTooltip
                ttk="{{!formGroup.valid ? 'invalid_form' : null}}"
                ttp="top"
                i18n
        >Anfrage senden</ion-button>
    </ng-container>
</app-modal>
