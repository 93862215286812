import { gql } from '@apollo/client/core';
import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { ApiService } from '../../../services/api.service';
import {
    CalendarSettingInterface,
    StatisticsSettingInterface,
    NotificationsSettingInterface, NotificationEnabledTypesInterface
} from '../../../interfaces/settings.interface';
import { SecurityStateVar } from '../../locals/securityState.var';


export const UpsertCalendarSettingsMutation = gql`
    mutation upsertCalendarSettingsMutation($calendar: [settingsCalendarSanacorpEvents_insert_input!]!) {
      insert_settingsCalendarSanacorpEvents(
          objects: $calendar,
          on_conflict: {
              constraint: settingsCalendarSanacorpEvents_userId_key,
              update_columns: [allowed]
          }
      ) {
        affected_rows
      }
    }
`;


export const UpsertNotificationSettingsMutation = gql`
    mutation insertNotificationSettingsMutation($notifications: [settingsNotification_insert_input!]!) {
        insert_settingsNotification(
            objects: $notifications,
            on_conflict: {
                constraint: settingsNotification_userId_key,
                update_columns: [notificationSoundEnabled, notificationSoundInterval, chatSoundEnabled, chatSoundInterval]
            }
        ) {
            affected_rows
        }
    }
`;

export const UpsertNotificationEnabledTypes = gql`
    mutation insertNotificationEnabledTypes($enabledTypes: [notificationEnabledTypes_insert_input!]!) {
        insert_notificationEnabledTypes(
            objects: $enabledTypes,
            on_conflict: {
                constraint: notificationEnabledType_pharmacyStoreId_type_key,
                update_columns: []
            }
        ) {
            affected_rows
        }
    }
`;

export const SubscribeToNewsletter = gql`
    mutation subscribeToNewsletter($listId: Int!) {
        SubscribeToNewsletter(listId: $listId) {
            status
            message
        }
    }
`;

export const UnsubscribeFromNewsletter = gql`
    mutation unsubscribeFromNewsletter($listId: Int!) {
        UnsubscribeFromNewsletter(listId: $listId) {
            status
            message
        }
    }
`;

export const DeleteNotificationEnabledTypes = gql`
    mutation deleteNotificationEnabledTypes($where: notificationEnabledTypes_bool_exp!) {
        delete_notificationEnabledTypes(where: $where) {
            affected_rows
        }
    }
`;

export const DeletePin = gql`
    mutation deletePin($id: Int!) {
        DeleteSecurityPin(id: $id) {
            status
            message
        }
    }
`;

export const ResetPINSettingsMutation = gql`
    mutation resetPINSettingsMutation {
        ResetPIN {
            status
            message
        }
    }
`;

export const UpsertUserSettingsMutation = gql`
    mutation insertUserSettingsMutation($key: String!, $value: String!) {
        insert_userSettings(
            objects: {key: $key, value: $value},
            on_conflict: {
                constraint: userSettings_userId_key_key,
                update_columns: [value]
            }
        ) {
            affected_rows
        }
    }
`;


export const UpsertPharmacySettingsMutation = gql`
    mutation upsertPharmacySettings($trackingEnabled: Boolean, $targetSales: Int) {
        insert_pharmacyStoreSettings(
            objects:[{trackingEnabled: $trackingEnabled, targetSales: $targetSales}],
            on_conflict: {
                constraint: pharmacyStoreSettings_pharmacyStoreId_key,
                update_columns: [trackingEnabled, targetSales]
            }) {
            affected_rows
        }
    }
`;

export const UpdateTrackingSettingMutation = gql`
    mutation updateTrackingSettingMutation($trackingEnabled: Boolean, $id: Int!) {
        update_pharmacyStoreSettings_by_pk(
            _set:{
                trackingEnabled: $trackingEnabled,
            },
            pk_columns: {id: $id}) {
            id
        }
    }
`;

export const UpdatePharmacySettingsMutation = gql`
    mutation updatePharmacySettings($targetSales: Int, $id: Int!) {
        update_pharmacyStoreSettings_by_pk(
            _set:{
                targetSales: $targetSales
            },
            pk_columns: {id: $id}) {
            id
        }
    }
`;


export const AllSettingsMutations = [
    UpsertCalendarSettingsMutation,
    UpsertNotificationSettingsMutation,
    UpsertNotificationEnabledTypes,
    DeleteNotificationEnabledTypes,
    SubscribeToNewsletter,
    UnsubscribeFromNewsletter,
    DeletePin,
    ResetPINSettingsMutation,
    UpsertUserSettingsMutation,
    UpsertPharmacySettingsMutation,
    UpdateTrackingSettingMutation,
    UpdatePharmacySettingsMutation
];

@Injectable()
export class SettingsMutations {
    private securityStateVar = inject(SecurityStateVar);

    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
    ) {}

    upsertNotificationSettings(notification: NotificationsSettingInterface) {
        this.apollo.mutate( {
            mutation: UpsertNotificationSettingsMutation,
            variables: {
                notifications: [{
                    notificationSoundEnabled: notification.common.soundEnabled,
                    notificationSoundInterval: notification.common.soundInterval,
                    chatSoundEnabled: notification.chat.soundEnabled,
                    chatSoundInterval: notification.chat.soundInterval
                }]
            }
        }).subscribe({
            error: error => this.apiService.presentErrorToast(error, 'Die Einstellungen für die Benachrichtigungen konnten nicht gespeichert werden.')
        });
    }

    upsertNotificationEnabledTypes(enabledTypes: NotificationEnabledTypesInterface[]) {
        this.apollo.mutate( {
            mutation: UpsertNotificationEnabledTypes,
            variables: {
                enabledTypes
            },
        }).subscribe({
            error: error => this.apiService.presentErrorToast(error, 'Die Einstellungen für die Benachrichtigungen konnten nicht gespeichert werden.')
        });
    }

    deleteNotificationEnabledTypes(disableTypes: NotificationEnabledTypesInterface[]) {
        const variables = {
            where: {
                _or: disableTypes.map(type => ({
                    type: {_eq: type.type},
                }))
            }
        };
        this.apollo.mutate( {
            mutation: DeleteNotificationEnabledTypes,
            variables,
        }).subscribe({
            error: error => this.apiService.presentErrorToast(error, 'Die Einstellungen für die Benachrichtigungen konnten nicht gespeichert werden.')
        });
    }

    subscribeToNewsletter(listId: number) {
        return new Promise((resolve, reject) => {
            this.apollo.mutate({
                mutation: SubscribeToNewsletter,
                variables: {
                    listId
                }
            }).subscribe({
                next: response => {
                    const result = response?.data?.['SubscribeToNewsletter'];
                    if (result?.status === 'ERROR') {
                        void this.apiService.presentErrorToast(result?.message, 'Die Anmeldung zum Newsletter konnte nicht abgeschlossen werden.');
                        return reject(result?.message);
                    }
                    resolve(true);
                },
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Die Anmeldung zum Newsletter konnte nicht abgeschlossen werden.');
                    reject(error);
                }
            });
        });
    }

    unsubscribeFromNewsletter(listId: number) {
        return new Promise((resolve, reject) => {
            this.apollo.mutate({
                mutation: UnsubscribeFromNewsletter,
                variables: {
                    listId
                }
            }).subscribe({
                next: response => {
                    const result = response?.data?.['UnsubscribeFromNewsletter'];
                    if (result?.status === 'ERROR') {
                        void this.apiService.presentErrorToast(result?.message, 'Die Abmeldung vom Newsletter konnte nicht abgeschlossen werden.');
                        return reject(result?.message);
                    }
                    resolve(true);
                },
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Die Abmeldung vom Newsletter konnte nicht abgeschlossen werden.');
                    reject(error);
                }
            });
        });
    }

    upsertStatisticSettings(statisticsData: StatisticsSettingInterface) {
        return this.savePharmacySettings(
            {targetSales: statisticsData.targetSales},
            statisticsData.id,
            'Änderungen wurde erfolgreich gespeichert!',
            'Beim Speichern der Änderungen ist ein Fehler aufgetreten.'
        );
    }

    upsertCalendarSettings(calendar: CalendarSettingInterface) {
        this.apollo.mutate( {
                mutation: UpsertCalendarSettingsMutation,
                variables: {
                    calendar: {
                        allowed: calendar.sanacorpEvents.allowed
                    }
                }
            }).subscribe({
            error: error => this.apiService.presentErrorToast(error, 'Die Kalendereinstellungen konnten nicht aktualisiert werden.')
        });
    }

    deletePin(id: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apollo.mutate({
                mutation: DeletePin,
                variables: {id}
            })
                .subscribe({
                    next: (result) => {
                        if (result?.data?.['DeleteSecurityPin']?.status === 'ERROR') {
                            void this.apiService.presentErrorToast(result?.data?.['DeleteSecurityPin']?.message, 'Beim deaktivieren Ihrer PIN ist ein Fehler aufgetreten.');
                            return reject(false);
                        }
                        void this.apiService.presentSuccessToast('Ihre PIN wurde erfolgreich deaktiviert.');
                        this.updateStatisticLockedStatus(false);
                        resolve(true);
                    },
                    error: error => {
                        void this.apiService.presentErrorToast(error, 'Beim deaktivieren Ihrer PIN ist ein Fehler aufgetreten.');
                        reject(false);
                    }
                });
        });
    }

    resetPINSetting(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apollo.mutate( {
                mutation: ResetPINSettingsMutation,
                variables: {}
            }).subscribe({
                next: _ => {
                    void this.apiService
                        .presentSuccessToast('Ihre PIN wurde erfolgreich beantragt. Sie erhalten in Kürze eine Mail mit Ihrer neuen PIN.');
                    this.updateStatisticLockedStatus(true);
                    resolve(true);
                },
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Bei der Änderung Ihrer PIN ist ein Fehler aufgetreten.');
                    reject(false);
                }
            });
        });
    }

    upsertUserSettings(key: string, value: string) {
        this.apollo.mutate( {
            mutation: UpsertUserSettingsMutation,
            variables: {key, value: value || ''}
        }).subscribe({
            error: error => this.apiService.presentErrorToast(error, 'Die Einstellungen für die Benachrichtigungen konnten nicht gespeichert werden.')
        });
    }

    upsertPharmacySettings(trackingEnabled: boolean, id): Promise<boolean> {
        return this.savePharmacySettings(
            {trackingEnabled},
            id,
            null,
            'Die Einstellungen für die Nutzungsanalyse konnten nicht gespeichert werden.'
        );
    }

    private savePharmacySettings = (variables, id, successMessage = null, errorMessage = null) : Promise<boolean> => {
        let mutation = UpsertPharmacySettingsMutation;
        if (id) {
            mutation = typeof variables.trackingEnabled === 'boolean' ? UpdateTrackingSettingMutation : UpdatePharmacySettingsMutation;
            variables = {...variables, id};
        } else {
            variables = {...{trackingEnabled: true, targetSales: null},...variables};
        }
        return new Promise( resolve => {
            this.apollo.mutate({
                mutation,
                variables,
            }).subscribe({
                next: _ => {
                    if (successMessage) {
                        void this.apiService.presentSuccessToast(successMessage);
                    }
                    resolve(true);
                },
                error: error => {
                    if (errorMessage) {
                        void this.apiService.presentErrorToast(error, errorMessage);
                    }
                    resolve(false);
                }
            });
        });
    }

    private updateStatisticLockedStatus(isLocked: boolean) {
        this.securityStateVar.set(isLocked);
    }
}
