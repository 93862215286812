import { EducationInterface } from '../../interfaces/education.interface';
import { MeaDownloadTypes } from '../../enums/mea.enum';
import { PharmacyStoreInterface } from '../../core.interfaces';

export function formatStrapiData(data) {
    return {id: data.id, ...data.attributes};
}

export function formatStrapiBannerData(dataObject) {
    return dataObject?.data?.map(data => ({
        ...data.attributes,
        id: data?.id,
        media: data?.attributes?.media?.map(it => ({
            size: it?.size,
            image: {
                url: it?.image?.data?.attributes?.url
            }
        }))
    }));
}

export function formatInfoModalData(dataObject) {
    return dataObject?.data?.map(data => ({
        id: parseInt(data?.id, 10),
        version: data?.attributes?.releaseNote?.data?.attributes?.version,
        entries: data?.attributes?.infoModalEntry?.map(entry => ({
            id: parseInt(entry?.id, 10),
            title: entry?.title,
            content: entry?.content
        }))
    }));
}

export function formatStrapiOffersConnectionData(dataObject) {
    return Array.isArray(dataObject?.data) ? dataObject?.data?.map(data => (formatStrapiOffer(data))) : formatStrapiOffer(dataObject);
}

function formatStrapiOffer(data) {
    const membershipTypes = [];
    data?.attributes?.offerFilters?.map(filter => filter?.membershipTypes?.data?.map(type => {
        const name = type?.attributes?.name;
        if (name && !membershipTypes.includes(name)) {
            membershipTypes.push(name);
        }
        return type;
    }));
    return data ? {
        ...data.attributes,
        id: data?.id,
        image: {
            url: data?.attributes?.image?.data?.attributes?.url
        },
        offerFilters: {
            membershipTypes: membershipTypes.map(type => ({name: type}))
        },
        offerProducts: data?.attributes?.offerProducts?.map(it => {
            return {
                ...it,
                offerProduct: {
                    productName: it?.offerProduct?.data?.attributes?.productName,
                    unit: it?.offerProduct?.data?.attributes?.unit,
                    vaccineDoses: it?.offerProduct?.data?.attributes?.vaccineDoses,
                    dosageForm: it?.offerProduct?.data?.attributes?.dosageForm,
                    pzn: it?.offerProduct?.data?.attributes?.pzn,
                    producer: it?.offerProduct?.data?.attributes?.producer,
                    aep: it?.offerProduct?.data?.attributes?.aep,
                    imageUrl: it?.offerProduct?.data?.attributes?.image?.data?.attributes?.url
                }
            };
        })
    } : data;
}

export function formatStrapiSeminarsData(dataObject, isArray = true) {
    if (isArray) {
        return dataObject?.data?.map(data => ({
            ...data.attributes,
            id: data?.id,
            seminar: {
                ...data?.attributes?.seminar?.data?.attributes,
                id: data?.attributes?.seminar?.data?.id,
                image: {
                    url: data?.attributes?.seminar?.data?.attributes?.image?.data?.attributes?.url
                }
            }
        }));
    }
    return {
        id: dataObject?.data?.id,
        ...dataObject?.data?.attributes,
        seminar: {
            ...dataObject?.data?.attributes?.seminar?.data?.attributes,
            id: dataObject?.data?.attributes?.seminar?.data?.id,
            image: {
                url: dataObject?.data?.attributes?.seminar?.data?.attributes?.image?.data?.attributes?.url
            }
        }
    };
}

export function formatStrapiNewsData(dataObject, isArray = true) {
    if (isArray) {
        return dataObject?.data?.map(data => ({
            ...data.attributes,
            id: data?.id,
            location: data?.attributes?.location?.data?.map(it => ({
                id: it.id
            })),
            formId: data?.attributes?.form?.data?.attributes?.publishedAt ? data?.attributes?.form?.data?.id : null,
            formButton: data?.attributes?.formButton,
            image: {
                url: data?.attributes?.image?.data?.attributes?.url,
                height: data?.attributes?.image?.data?.attributes?.height,
                width: data?.attributes?.image?.data?.attributes?.width
            }
        }));
    }
    const attributes = dataObject?.attributes;
    const attachment = attributes?.attachments?.data?.attributes;
    return {
        id: dataObject?.id,
        ...attributes,
        attachments: attachment ? [attachment] : null,
        location: attributes?.location?.data?.id,
        formId: attributes?.form?.data?.attributes?.publishedAt ? attributes?.form?.data?.id : null,
        formButton: attributes?.formButton,
        image: {
            url: attributes?.image?.data?.attributes?.url,
            height: attributes?.image?.data?.attributes?.height,
            width: attributes?.image?.data?.attributes?.width
        }
    };
}

export function formatStrapiForm(dataObject) {
    const attributes = dataObject?.data?.attributes;
    const formFields = attributes?.formFields;
    return {
        id: dataObject?.data?.id,
        title: attributes?.title,
        ...attributes,
        formFields,
    };
}

export function formatFormResult(dataObject) {
    return dataObject?.map(data => ({
        id: data?.id,
    }));
}

export function formatStrapiServiceBranches(branches) {
    return branches?.data?.map(data => ({
        ...data.attributes,
        id: data?.id,
        teamMembers: data?.attributes?.teamMembers?.data?.map(it => ({
            ...it.attributes,
            image: it?.attributes?.url
        })),
        image: data?.attributes?.image?.data?.attributes?.url,
        file: data?.attributes?.file?.data?.attributes?.url,
    }));
}

export function formatStrapiHelpSections(sections) {
    return sections?.data?.map(data => ({
        ...data.attributes,
        id: data?.id,
        faqs: data?.attributes?.faqs?.data?.map(it => ({
            ...it.attributes,
            id: it.id,
        })),
        contents: data?.attributes?.contents?.data?.map(it => ({
            ...it.attributes,
            id: it.id
        }))
    }));
}

export function formatStrapiDownloads(downloads, meaDownloadType: string) {
    return downloads?.data
        ?.filter(data => data?.attributes?.downloadTypes?.data?.length > 0)
        ?.map(data => ({
            ...data.attributes,
            id: data?.id,
            file: data?.attributes?.file?.data?.attributes?.url,
            downloadTypes: data?.attributes?.downloadTypes?.data
                ?.filter((type: any) =>
                    !meaDownloadType ||
                    meaDownloadType === MeaDownloadTypes.ALL ||
                    meaDownloadType === type?.attributes?.type
                )
                ?.map((type: any) => type?.attributes),
            isSelected: false
        }));
}

export function formatStrapiEducations(educations): Array<EducationInterface> {
    return educations?.data?.map(data => formatStrapiEducation(data));
}

export function formatStrapiEducation(data): EducationInterface {
    if (!data) return;

    const education = {
        ...data.attributes,
        id: data?.id,
        category: data?.attributes?.educationCategory?.data?.attributes?.category,
        location: {
            ...data?.attributes?.educationLocation?.data?.attributes,
            name: data?.attributes?.educationLocation?.data?.attributes.location,
            id: data?.attributes?.educationLocation?.data?.id
        },
        imageUrl: data?.attributes?.image?.data?.attributes?.url
    };

    delete education.educationCategory;
    delete education.educationLocation;
    delete education.image;
    delete education._typename;

    return education;
}

export function getFiltersStrapi(pharmacy: PharmacyStoreInterface) {
    return {
        filter: {
            and: [
                {
                    customerNumbersList: {
                        or: [{customerNumber: {eq: pharmacy?.apiUser}}, {customerNumber: {null: true}}],
                    }
                },
                {
                    or: [
                        {
                            chatUserOnly: {
                                in: [
                                    'alle',
                                    pharmacy?.chatAvailable ? 'hatChat' : 'keinChat'
                                ]
                            }
                        },
                        {chatUserOnly: {null: true}}
                    ],
                },
                {
                    or: [
                        {
                            shopUserOnly: {
                                in: [
                                    'alle',
                                    pharmacy?.shopAvailable ? 'hatShop' : 'keinShop'
                                ]
                            }
                        },
                        {shopUserOnly: {null: true}}
                    ]
                },
                {
                    sanacorpLocations: {
                        or: [
                            {id: {eq: pharmacy?.sanacorpLocation}},
                            {id: {null: true}}
                        ]
                    }
                },
                {
                    membershipTypes: {
                        or: [
                            {name: {eq: pharmacy?.membershipType}},
                            {name: {null: true}}
                        ]
                    }
                }

            ]
        },
    };
}
