import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NotificationInterface, OrderDetailInterface, ReturnsDetailInterface } from '../../../../core/core.interfaces';
import { InformationMutations, NotificationQueries, OrderQueries, ReturnsQueries } from '../../../../core/core.store';
import { ModalService } from '../../../../core/services/modal.service';
import { NotificationsEnum } from '../../../../core/enums/notifications.enum';
import { NotificationTypeLabelConfig } from '../../../../core/config/notification-type.config';
import { ModalClassEnum } from '../../../../core/enums/modal-class.enum';
import { OrderService } from '../../../../core/services/order.service';
import { CommunicationZoneFormComponent } from '../../../communications/pages/communication-zone/widgets/communication-zone-form/communication-zone-form.component';
import { unsubscribe, unsubscribeAll } from '../../../../core/core.utils';
import { OffersAppAreaEnum } from '../../../../core/enums/offers.enum';
import { NotificationGoToEntryService } from '../../../../core/services/notification-go-to-entry.service';
import { getReturnsReplacementArray } from '../../../../core/config/communication-zone-forms.config';
import { CommunicationZoneType } from '../../../../core/core.enums';

@Component({
    selector: 'app-notification-view',
    templateUrl: './notification-view.component.html',
    styleUrls: ['./notification-view.component.scss'],
})
export class NotificationViewComponent implements OnInit, OnChanges {

    constructor(
        private router: Router,
        private modalService: ModalService,
        private notificationQueries: NotificationQueries,
        private informationMutations: InformationMutations,
        private notificationGoToEntryService: NotificationGoToEntryService,
        private orderService: OrderService,
        private orderQueries: OrderQueries,
        private returnsQueries: ReturnsQueries,
    ) {
    }
    static modalClass = ModalClassEnum.autoHeightMedium;

    @Input() notificationId: number;
    @Input() isUnreadButton = true;
    @Input() type: NotificationsEnum = null;
    @Input() notificationItem: NotificationInterface = null;

    order: OrderDetailInterface;
    returns: ReturnsDetailInterface;
    notification: NotificationInterface;
    notificationObservable: Subscription;
    orderDetailSubscription: Subscription;
    returnsDetailSubscription: Subscription;
    setReadStatus = true;
    isLoading = true;
    notificationsTypeEnum = NotificationsEnum;
    notificationTypeTrans = '';

    protected readonly NotificationsEnum = NotificationsEnum;

    ngOnInit() {
       this.refreshData();
       if(this.notificationItem) {
           this.notification = this.notificationItem;
           this.isLoading = false;
       }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.notificationId) {
            this.refreshData();
        }
    }
    ionViewWillLeave() {
        if (this.setReadStatus) {
            this.changeNotificationReadState(true);
        }
        unsubscribeAll([
            this.notificationObservable,
            this.orderDetailSubscription,
            this.returnsDetailSubscription
        ]);
    }

    /**
     * Marks notification as unread
     */
    async markAsUnread() {
        this.changeNotificationReadState(false);
        this.setReadStatus = false;
        await this.modalService.dismiss();
    }

    /**
     * Refreshes the data
     */
    refreshData() {
        if(this.notificationItem) {
            this.setNotificationTypeTrans(this.notificationItem.type, this.notificationItem.payload);
        }

        // data from input do not contain all data necessary for the goToEntry-method
        unsubscribe(this.notificationObservable);
        this.notificationObservable = this.notificationQueries.getNotificationById(this.notificationId).subscribe(notification => {
            if(notification) {
                this.notification = notification;
                this.isLoading = false;
                this.setNotificationTypeTrans(notification.type, notification.payload);
                unsubscribe(this.notificationObservable);

                if (notification?.payload?.realOrderId) {
                    unsubscribe(this.orderDetailSubscription);
                    this.orderDetailSubscription = this.orderQueries.getOrderById(parseInt(this.notification.payload.realOrderId, 10))
                        .subscribe(async order => {
                            this.order = order;
                        });
                }
                if (this.notification?.payload?.returnsId) {
                    unsubscribe(this.returnsDetailSubscription);
                    this.returnsDetailSubscription = this.returnsQueries.getReturnById(parseInt(this.notification.payload.returnsId.toString(), 10))
                        .subscribe(async returns => {
                            this.returns = returns;
                        });
                }
            }
        });

    }

    /**
     * sets the notification type translation
     *
     * @param type : NotificationsEnum
     * @param payload : json
     */
    setNotificationTypeTrans(type: NotificationsEnum, payload) {
        const notificationTitle = NotificationTypeLabelConfig[type];
        if(payload) {
            let searchTerm: string;
            let replaceTerm: string;
            switch (type) {
                case NotificationsEnum.NEW_OFFER:
                    searchTerm = 'appArea';
                    replaceTerm = payload.appArea?.includes(OffersAppAreaEnum.SANACORP) ? 'Sanacorp' : 'mea';
                    break;
                default:
                    searchTerm = 'productName';
                    replaceTerm = payload[searchTerm];
            }
            this.notificationTypeTrans = notificationTitle ?
                notificationTitle.replace(`{{${searchTerm}}}`, replaceTerm) :
                notificationTitle;
        } else {
            this.notificationTypeTrans = '';
        }
    }

    /**
     * Update read status of notification
     *
     * @param isRead - true if notification is read
     */
    changeNotificationReadState(isRead) {
        const notificationIsAlreadyRead = this.notification?.isRead ?? false;
        if (this.notification && isRead !== notificationIsAlreadyRead) {
            this.informationMutations.upsertNotificationReadStatus(this.notification.id, isRead);
        }
    }


    /**
     * Delete single notification
     */
    async onDeleteClick() {
        if(this.notificationId) {
            this.informationMutations.deleteNotification(this.notificationId);
            this.refreshData();
            this.setReadStatus = false;
            await this.modalService.dismiss();
        }
    }

    /**
     * Archive single notification
     */
    async onChangeArchiveStatusClick() {
        if(this.notificationId) {
            this.informationMutations.archiveNotification(this.notificationId);
            this.refreshData();
            this.setReadStatus = false;
            await this.modalService.dismiss();
        }
    }

    /**
     * Go to selected entry
     */
    async goToEntry() {
        await this.notificationGoToEntryService.goToEntry(this.notification);
    }

    /**
     * Go to selected entry
     */
    async additionalActions() {
        switch (this.type) {
            case NotificationsEnum.ORDER_DISPO_DIRECT_OUT_OF_DATE:
                await this.notificationGoToEntryService.goToEntry(this.notification);
                await this.orderService.presentAcknowledgeAlert(this.order);
                break;
            case NotificationsEnum.RETURNS_PRODUCT_MISSING:
            case NotificationsEnum.RETURNS_STOCK_ERROR:
                await this.modalService.dismiss();
                const modal = await this.modalService
                    .create(CommunicationZoneFormComponent, {
                        id: this.notification.payload?.returnsId,
                        type: CommunicationZoneType.RETURN,
                        isReadOnly: true,
                        replaceArray: getReturnsReplacementArray(true, this.returns)
                    });
                await this.modalService.present(modal);
                break;
        }
    }
}
