import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
    CommunicationZoneFilterEnum,
    FetchPolicyKeys as FPK,
    DataChangedKeys as DCK,
    QueryFetchPolicy,
    SortDirectionEnum,
    CommunicationZoneType
} from '../../../core.enums';
import { CommunicationZoneInterface } from '../../../interfaces/communication-zone.interface';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { SettingsQueries } from './settings.graphql';
import { ProfileSettingsVar } from '../../locals/profileSettings.var';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { convertFromLocalTimeToUTC, endOf } from '../../../core.formatting';
import { getDateRangesWithoutDates } from '../../../core.config';

const GetCommunicationZone = (queryName) => gql`
    query ${queryName}($where: communicationZone_bool_exp, $limit: Int, $order_by: [communicationZone_order_by!], $offset: Int) {
        communicationZone(where: $where, limit: $limit, order_by: $order_by, offset: $offset) {
            id
            title
            content
            contactPerson
            notes {
                note
            }
            payload
            created_at
            solved
            orderId
            type
            userDetails {
                firstName
                lastName
            }
        }
    }
`;

const GetCommunicationZoneById = (queryName) => gql`
    query ${queryName}($id: Int!) {
        communicationZone_by_pk(id: $id) {
            id
            title
            content
            contactPerson
            notes {
                note
            }
            payload
            created_at
            solved
            orderId
            type
            userDetails {
                firstName
                lastName
            }
        }
    }
`;
export const AllCommunicationZoneQueries = [
    GetCommunicationZone('test'),
    GetCommunicationZoneById('test')
];

@Injectable()
export class CommunicationZoneQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getCommunicationZone]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getCommunicationZoneById]: QueryFetchPolicy.NETWORK_ONLY,
    };

    constructor(
        private apollo: Apollo,
        private settingsQueries: SettingsQueries,
        private profileSettingsVar: ProfileSettingsVar,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.communicationZoneChanged]: [
                FPK.getCommunicationZone,
                FPK.getCommunicationZoneById
            ],
            [DCK.noteChanged]: [
                FPK.getCommunicationZone,
                FPK.getCommunicationZoneById
            ]
        });
    }

    /**
     * Filters for solved/unsolved requests
     *
     * @param filterVariables
     * @param isSolved
     * @static
     */
    static getRequestBySolved(filterVariables, isSolved: boolean) {
        filterVariables['where']['_and'].push(
            {solved: {_eq: isSolved}}
        );
    }

    /**
     * Returns all communication zone
     *
     * @param offset - Offset for pagination
     * @param limit - Maximum number of items to return
     * @param search - Search text
     * @param filter - Filter
     * @param dateOption - Date option
     * @param dateFrom - Date from
     * @param dateTo - Date to
     * @param types - Types
     */
    public getCommunicationZone(
        offset= 0,
        limit= 0,
        search= '',
        filter,
        dateOption,
        dateFrom?,
        dateTo?,
        types?: CommunicationZoneType[]
    ): Observable<CommunicationZoneInterface[]> {
        const variables = {};
        variables['order_by'] = [{created_at: SortDirectionEnum.desc}];

        if(limit > GraphQLLimits.communicationZone) {
            limit = GraphQLLimits.communicationZone;
            console.error('Limit above maximum!');
        }

        if (limit > 0) {
            variables['limit'] = limit;
        }

        if (offset > 0) {
            variables['offset'] = offset;
        }

        variables['where'] = {_and: []};

        if (!getDateRangesWithoutDates(dateOption) && dateFrom && dateTo) {
            variables['where']['_and'].push({
                created_at: {
                    _gte: convertFromLocalTimeToUTC(dateFrom),
                    _lte: convertFromLocalTimeToUTC(endOf('day', true, dateTo))
                }
            });
        }

        if (types?.length > 0) {
            variables['where']['_and'].push({type: {_in: types}});
        }

        switch (filter) {
            case CommunicationZoneFilterEnum.allRequestsSolved:
                CommunicationZoneQueries.getRequestBySolved(variables, true);
                break;
            case CommunicationZoneFilterEnum.allRequestsUnsolved:
                CommunicationZoneQueries.getRequestBySolved(variables, false);
                break;
            case CommunicationZoneFilterEnum.myRequests:
            default:
                this.getMyRequestFilter(variables);
                break;
        }
        if (search !== '') {
            variables['where']['_and'].push({
                _or: [
                    {title: {_ilike: `%${search}%`}},
                    {content: {_ilike: `%${search}%`}},
                    {notes: {note: {_ilike: `%${search}%`}}},
                ]
            });
        }

        const fetchPolicyKey = FPK.getCommunicationZone;
        return this.apollo.watchQuery({
                query: GetCommunicationZone(fetchPolicyKey),
                variables,
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['communicationZone']))
            .pipe(map((data) => {
              if (data) {
                  this.updateFetchPolicy(fetchPolicyKey);
                  return data.map(d => {
                      const { type, ...rest } = d;
                      return {
                          ...rest,
                          entryType: type
                      };
                  });
              }
              return data;
            })) as Observable<CommunicationZoneInterface[]>;
    }

    /**
     * Filters for myRequests, c.f. CommunicationZoneFilterEnum
     *
     * @param filterVariables - array containing the filters for args of getCommunicationZone()
     * @private
     */
    private getMyRequestFilter(filterVariables) {
        filterVariables['where']['_and'].push({
            userId: {_eq: this.profileSettingsVar.profileSettings().user.id}
        });
    }

    /**
     * Returns the requested communication zone
     *
     * @param id - Id of the communication zone
     */
    public getCommunicationZoneById(id: number): Observable<CommunicationZoneInterface> {

        const fetchPolicyKey = FPK.getCommunicationZoneById;
        return this.apollo.watchQuery({
            query: GetCommunicationZoneById(fetchPolicyKey),
            variables: {id},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['communicationZone_by_pk']))
            .pipe(map(d => {
                        if (d) {
                            // map type onto entryType for the Interface
                            const { type, ...rest } = d;
                            this.updateFetchPolicy(fetchPolicyKey);
                            return {
                                ...rest,
                                entryType: type
                            };
                        }
                        return d;
                    })) as Observable<CommunicationZoneInterface>;
    }
}

