import { startOf } from '../formatting/date.formatting';

/**
 * Returns the 01.01. of the current year minus two
 * @example If the date is 16.08.2024, the function will return the 01.01.2022. If the date is 16.08.2022, the function will return the 01.01.2020.
 * @returns the minimum date for logistics
 */
export const getMinLogisticsDate = (): Date => new Date(startOf('year', false, null, 2));

/**
 * Checks whether the date is too far in the past (older than 01.01. of the current year minus two)
 * If the date is too old, the function returns the minimum date for logistics. Otherwise, the function returns the date.
 * @returns A valid date (either minimum date or younger date)
 */
export const checkForMinDate = (date: string) => {
    const minDate = getMinLogisticsDate();
    if (new Date(date) < minDate) {
        return minDate;
    }
    return date;
};
