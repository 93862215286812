import { Injectable, Signal, inject } from '@angular/core';
import { ProfileSettingsVar } from '../store/locals/profileSettings.var';
import { UserStorageInterface } from '../interfaces/user-storage.interface';
import { UserStorageMutations } from '../store/graphql/mutations/user-storage.graphql';
import { ProfileSettingInterface } from '../core.interfaces';

@Injectable({
    providedIn: 'root'
})
export class ContactPersonService {
    private profileSettingsVar = inject(ProfileSettingsVar);

    profileSettings: Signal<ProfileSettingInterface> = this.profileSettingsVar.profileSettings;

    constructor(
        private userStorageMutations: UserStorageMutations
    ) {}

    /**
     * Set a single contact name into user storage.
     *
     * @param contact string: full name of the contact person
     * @returns Promise<Array<string>>
     */
    async set(contact: string): Promise<Array<string>> {
        if (contact?.length === 0) {
            return  null;
        }

        const storedContactNames = this.get();
        let contactNames: Array<string> = [];
        if (storedContactNames.findIndex(it => it === contact) > -1) {
            return storedContactNames;
        }
        if (storedContactNames && storedContactNames.length > 0 && storedContactNames.length < 5) {
            contactNames = [contact, ...storedContactNames];
        } else if (storedContactNames?.length >= 5) {
            contactNames = [contact, ...storedContactNames.slice(0, storedContactNames.length - 1)];
        } else {
            contactNames = [contact];
        }
        const userStorageInput: UserStorageInterface = {
            storage: {
                contactNames
            }
        };
        await this.profileSettingsVar.setContactNames(contactNames);
        this.userStorageMutations.upsertUserStorage(userStorageInput);
        return contactNames;
    }

    /**
     * Get the contact person names
     */
    get(): Array<string> {
        return this.profileSettings().contactNames;
    }
}
