<ion-card class="ion-no-margin" id="{{cardId}}"
          (scroll)="scrollableOverflow && onCardScroll($event)"
          [style.--background-color]="backgroundColor"
          [style.--color]="textColor"
          [class.outer-padding]="useOuterPadding"
          [class.ion-padding]="!isStandalone"
          [class.ion-no-padding]="noPadding"
          [class.custom-padding-left]="customPaddingLeft"
          [class.no-border-radius]="noBorderRadius"
          [class.not-standalone]="!isStandalone"
          [class.overflow-scroll]="scrollableOverflow"
          [class.overflow-visible]="overflowVisible"
          [class.hide-shadow]="hideBoxShadow"
          [class.has-min-height]="hasMinHeight"
>
    <ion-card-header class="ion-no-padding ion-align-items-center"
                     [class.no-padding-header]="noPaddingHeader" *ngIf="displayHeader">
        <ion-card-title [style.--color]="textColor" class="title-container">
            {{ title }}
            <div *ngIf="iconName" appTooltip [ttk]="ttk">
                <ion-icon [name]="iconName" [color]="iconColor"></ion-icon>
            </div>
        </ion-card-title>
        <ng-content select="[headerActions]"></ng-content>
    </ion-card-header>
    <ion-card-content
        class="ion-no-padding"
        [class.ion-margin-top]="!removeMarginTop"
        [class.widget-no-footer]="!footer"
        [class.widget-card-content-scrollable]="scrollableContent"
        [class.full-height]="contentFullHeight"
    >
        <div class="widget-card-content" >
            <ng-content select="[content]"></ng-content>
        </div>
        <div class="widget-card-footer" *ngIf="isStandalone">
            <ng-content select="[footer]"></ng-content>
        </div>
    </ion-card-content>
</ion-card>
